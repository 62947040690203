import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { CookieState } from '../types'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export const cookieStore: Module<CookieState, RootState> = {
  namespaced: true,
  state: {
    ageConfirmed: false,
    currencyCode: null
  },
  mutations,
  actions,
  getters
}
