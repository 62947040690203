import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { SearchQuery } from 'storefront-query-builder'
import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import config from 'config'
import { getThumbnailPath, productThumbnailPath } from '@vue-storefront/core/helpers';
import Vue from 'vue';

export const profileStore = {
  namespaced: true,
  actions: {
    async loadReviews (context, { customerId, approved = true, start = 0, size = 50, entityType = 'review', sort = 'id:desc', excludeFields = null, includeFields = null }) {
      let query = new SearchQuery()
      const cacheTags = []

      if (customerId) {
        query = query.applyFilter({ key: 'customer_id', value: { 'eq': customerId } })
        cacheTags.push('U' + customerId)
      }
      if (approved) {
        query = query.applyFilter({ key: 'review_status', value: { 'eq': 1 } })
      }

      const reviewResponse = await quickSearchByQuery({
        query,
        start,
        size,
        entityType,
        sort,
        storeCode: config?.defaultStoreCode || null,
        excludeFields,
        includeFields,
        cacheTags
      })

      context.commit('review/review/UPD_REVIEWS', reviewResponse, { root: true })
      return reviewResponse
    },
    loadProducts ({ dispatch }, reviews) {
      const productId = reviews.reviews.items.map(item => item.product_id)

      let query = new SearchQuery()
      query = query
        .applyFilter({ key: 'id', value: { 'in': productId } })
        .applyFilter({ key: 'status', value: { 'in': [0, 1] } })

      return dispatch('product/findProducts', { query: query }, { root: true })
    },
    async loadOrderedProducts ({ commit, dispatch, rootGetters }, prodList) {
      let productList = []
      let ids = []
      const orderDates = []
      prodList.forEach(product => {
        ids.push(product.product_id)
        orderDates[product.product_id] = product.created_at;
      })

      let query = new SearchQuery()
      query = query
        .applyFilter({ key: 'id', value: { 'in': ids } })
        .applyFilter({ key: 'status', value: { 'in': [0, 1] } })

      let { items: parentsList } = await dispatch('product/findProducts', { query: query }, { root: true })

      if (parentsList?.length) {
        for (let product of parentsList) {
          if (product.hasOwnProperty('image') && product.image === 'no_selection') {
            let { items: item } = await dispatch('loadParentProduct', product.id)
            if (item?.length > 0) {
              item = item[0]
              const thumbnail = productThumbnailPath(item)
              Vue.set(item, 'src', getThumbnailPath(thumbnail, config.products.thumbnails.width, config.products.thumbnails.height))
              item.created_at = orderDates[product.id]
              productList.push(item)
            }
          } else {
            const thumbnail = productThumbnailPath(product)
            Vue.set(product, 'src', getThumbnailPath(thumbnail, config.products.thumbnails.width, config.products.thumbnails.height))
            product.created_at = orderDates[product.id]
            productList.push(product)
          }
        }
      }
      return productList
    },
    loadParentProduct ({ dispatch }, id) {
      let query = new SearchQuery()
      query = query
        .applyFilter({ key: 'configurable_children.id', value: id })
        .applyFilter({ key: 'status', value: { 'in': [0, 1] } })
      return dispatch('product/findProducts', { query: query }, { root: true })
    }
  }
}
