import { Module } from 'vuex'
import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import RootState from '@vue-storefront/core/types/RootState'
import merge from 'lodash-es/merge'
import actions from './actions'
import { cartStore } from '@vue-storefront/core/modules/cart/store'

const cartExtendedModule = merge(cartStore, {
  actions
})

export const cartNewModule: Module<CartState, RootState> = cartExtendedModule
