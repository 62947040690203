import { isServer } from '@vue-storefront/core/helpers'
import RootState from '@vue-storefront/core/types/RootState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { Store } from 'vuex'

export function beforeRegistration (config: any, store: Store<RootState>) {
  if (!isServer && config.eWay && !window['eWAY']) {
    const env = config.eWay.env || 'sandbox'

    Logger.debug('Loading eWay script for ' + env, 'eWAY')()

    let url = 'https://secure.ewaypayments.com/scripts/eWAY.min.js'

    if (env === 'sandbox') {
      url = 'https://secure.ewaypayments.com/scripts/eWAY.js'
    }

    // load script for eWay
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      url
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
