import { Logger } from '@vue-storefront/core/lib/logger'
import MailItem from '../types/MailItem'
import { Module } from 'vuex'
import config from 'config'
import { processURLAddress } from '@vue-storefront/core/helpers'

export const mailerStore: Module<any, any> = {
  namespaced: true,
  actions: {
    async sendEmail (context, letter: MailItem) {
      try {
        return await fetch(
          processURLAddress(config.mailer.magento.send),
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ...letter
            })
          }
        )
      } catch (e) {
        Logger.error(e, 'magento-mailer')()
        throw new Error(e)
      }
    }
  }
}
