import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import RootState from '@vue-storefront/core/types/RootState'
import { Store } from 'vuex'
import { EWAY_PAYMENT_CODE, KEY } from '..'
import { Logger } from '@vue-storefront/core/lib/logger'

export function afterRegistration (config: any, store: Store<RootState>) {
  let correctPaymentMethod = false

  const placeOrder = () => {
    if (correctPaymentMethod) {
      const payload = {
        SecuredCardData: store.state[KEY].securedCardData
      }

      if (store.state[KEY].selectedCardId) {
        payload['TokenAction'] = 'use'
        payload['TokenID'] = Number(store.state[KEY].selectedCardId)
      }

      EventBus.$emit('checkout-do-placeOrder', payload)
    } else {
      Logger.debug('Not an eWAY payment method. Skipping', 'eWAY')()
    }
  }

  if (!isServer) {
    // Register the handler for what happens when they click the place order button.
    EventBus.$on('checkout-before-placeOrder', placeOrder)

    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      correctPaymentMethod = paymentMethodCode === EWAY_PAYMENT_CODE

      Logger.debug('Payment method changed to: ' + paymentMethodCode, 'eWAY')()

      // set eway valid if its another payment method, set invalid, if payment method is eWay
      // for validation purposes
      store.commit('payment-eway/SET_EWAY_VALID', !correctPaymentMethod)
    })
  }
}
