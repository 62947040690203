import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { cartNewModule } from './store'
import { extendStore } from '@vue-storefront/core/helpers';
import { cartHooks } from '@vue-storefront/core/modules/cart/hooks';

export const CartExtended: StorefrontModule = ({ store }) => {
  extendStore('cart', cartNewModule)

  cartHooks.beforeSync(({ serverItems, clientItems }) => {
    store.dispatch('ui/setTotalsLoading', true, { root: true })
    return { serverItems, clientItems }
  })
  cartHooks.beforeAddToCart(({ cartItem }) => {
    store.dispatch('ui/setTotalsLoading', true, { root: true })
    return { cartItem }
  })
  cartHooks.beforeRemoveFromCart(({ cartItem }) => {
    store.dispatch('ui/setTotalsLoading', true, { root: true })
    return { cartItem }
  })
  cartHooks.afterSync(() => {
    store.dispatch('ui/setTotalsLoading', false, { root: true })
  })
}
