import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { isServer } from '@vue-storefront/core/helpers'
import { AsyncDataLoader } from '@vue-storefront/core/lib/async-data-loader'
import { SET_STORE_CURRENCY } from './store/mutation-types'
import Cookies from 'js-cookie'

export const KEY = 'eagle-leather'

export const EagleLeather: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, module)
}
