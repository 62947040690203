import { Module } from 'vuex'
import { UrlState } from '@vue-storefront/core/modules/url/types/UrlState'
import merge from 'lodash/merge'
import RootState from '@vue-storefront/core/types/RootState'
import { urlStore } from '@vue-storefront/core/modules/url/store'
import actions from './actions'

const urlExtendedModule = merge(urlStore, {
  actions
})

export const urlExtended: Module<UrlState, RootState> = urlExtendedModule
