const Home = () => import(/* webpackChunkName: "vsf-cms" */ 'theme/pages/Home.vue')
const PageNotFound = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/PageNotFound.vue')
const ErrorPage = () => import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error.vue')
const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue')
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue')
const CmsPage = () => import(/* webpackChunkName: "vsf-cms" */ 'theme/pages/CmsPage.vue')
const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue')
const MyAccount = () => import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount.vue')
const Static = () => import(/* webpackChunkName: "vsf-static" */ 'theme/pages/Static.vue')
const ResetPassword = () => import(/* webpackChunkName: "vsf-reset-password" */ 'theme/pages/ResetPassword.vue');
const StyleGuide = () => import(/* webpackChunkName: "vsf-style-guide" */ 'theme/pages/StyleGuide.vue');
const BlogCategory = () => import(/* webpackChunkName: "vsf-aheadworks-blog-category" */ 'src/themes/theme-eagle-leather/pages/BlogCategory.vue')
const BlogPost = () => import(/* webpackChunkName: "vsf-aheadworks-blog-post" */ 'src/themes/theme-eagle-leather/pages/BlogPost.vue')
const Contact = () => import(/* webpackChunkName: "vsf-contact" */ 'theme/pages/Contact.vue')
const Cart = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Cart.vue')
const SearchResults = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/SearchResults.vue')
const Rewards = () => import(/* webpackChunkName: "vsf-rewards" */ 'theme/pages/Rewards.vue')
const Wishlist = () => import(/* webpackChunkName: "vsf-rewards" */ 'theme/pages/Wishlist.vue')
const Sizing = () => import(/* webpackChunkName: "vsf-sizing" */ 'theme/pages/Sizing.vue')

let routes = [
  { name: 'home', path: '/', component: Home, alias: ['/pwa.html', '/home'] },
  { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'cart', path: '/checkout/cart', component: Cart },
  { name: 'my-account', path: '/my-account', component: MyAccount },
  { name: 'my-shipping-details', path: '/my-account/shipping-details', component: MyAccount, props: { activeBlock: 'MyShippingDetails' } },
  { name: 'my-newsletter', path: '/my-account/newsletter', component: MyAccount, props: { activeBlock: 'MyNewsletter' } },
  { name: 'my-orders', path: '/my-account/orders', component: MyAccount, props: { activeBlock: 'MyOrders' } },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: MyAccount, props: { activeBlock: 'MyOrder' } },
  { name: 'my-purchases', path: '/my-account/purchases', component: MyAccount, props: { activeBlock: 'MyPurchases' } },
  { name: 'my-reviews', path: '/my-account/reviews', component: MyAccount, props: { activeBlock: 'MyReviews' } },
  { name: 'your-rewards', path: '/my-account/your-rewards', component: MyAccount, props: { activeBlock: 'MyRewardPoints' } },
  { name: 'my-recently-viewed', path: '/my-account/recently-viewed', component: MyAccount, props: { activeBlock: 'MyRecentlyViewed' } },
  { name: 'contact', path: '/contact', component: Contact, meta: { title: 'Contact Us' } },
  { name: 'error', path: '/error', component: ErrorPage, meta: { layout: 'minimal' } },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: CmsPage },
  { name: 'create-password', path: '/create-password/token/:token/email/:email', component: ResetPassword },
  { name: 'page-not-found', path: '*', component: PageNotFound },
  { name: 'style-guide', path: '/style-guide', component: StyleGuide, meta: { layout: 'empty' } },
  { name: 'aheadworks-blog-category', path: '/b/category/:slug', component: BlogCategory },
  { name: 'aheadworks-blog-post', path: '/b/:slug', component: BlogPost },
  { name: 'aheadworks-blog', path: '/b', component: BlogCategory },
  { name: 'reward-points', path: '/my-account/reward-points', component: MyAccount, props: { activeBlock: 'MyRewardPoints' }, meta: { requiresAuth: true } },
  { name: 'search-results', path: '/search/:query', component: SearchResults, meta: { layout: 'no-index' } },
  { name: 'Rewards', path: '/rewards', component: Rewards },
  { name: 'Wishlist', path: '/wishlist', component: Wishlist },
  { name: 'Sizing', path: '/sizing', component: Sizing, meta: { title: 'Leather Size Guide' } },
  { name: 'page-not-found', path: '*', component: PageNotFound }
]

export default routes
