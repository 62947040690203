import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'

export const getPdf = {
  namespaced: true,
  state: {
    invoice: []
  },
  mutations: {
    GET_INVOICE (state, invoice) {
      state.invoice = invoice || []
    }
  },
  actions: {
    async loadInvoicePdf ({ getters, commit }, orderId) {
      const url: string = processLocalizedURLAddress(config.checkout.endpoint.getOrderInvoice).replace('{{orderId}}', orderId)

      const response = await TaskQueue.execute({
        url: url,
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/pdf' },
          mode: 'cors'
        }
      })

      if (response.code === 200 && response.result) {
        commit('invoice/GET_INVOICE', response.result, { root: true })
      } else {
        commit('invoice/GET_INVOICE', [], { root: true })
      }
    }
  },
  getters: {
    getInvoice (state) {
      return state.invoice.length ? state.invoice : null
    }
  }
}
