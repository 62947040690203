import { MutationTree } from 'vuex';
import State from '../types/state';
import * as types from '../types/mutation-types'

const mutations: MutationTree<State> = {
  [types.SET_MODULE_LOADED] (state, payload) {
    state.isModuleLoaded = payload
  },
  [types.SET_CODES] (state, payload) {
    state.codes = payload
  },
  [types.SET_CITIES] (state, payload) {
    state.cities = payload
  },
  [types.SET_STATES] (state, payload) {
    state.states = payload
  }
}

export default mutations
