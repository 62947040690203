import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { beforeRegistration } from './hooks/beforeRegistration'
import { afterRegistration } from './hooks/afterRegistration'

export const KEY = 'payment-afterpay'

export const VSF_AFTERPAY_CODE = 'afterpaypayovertime'

export const PaymentAfterpay: StorefrontModule = function ({ store, router, appConfig }) {
  beforeRegistration(appConfig, store)
  store.registerModule(KEY, module)
  afterRegistration(appConfig, store)
}
