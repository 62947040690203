import { isServer } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

import { AsyncDataLoader } from '@vue-storefront/core/lib/async-data-loader'
import { cookieStore } from './store'
import { SET_AGE_CONFIRMED, SET_CURRENCY_COOKIE } from './store/mutation-types'
import Cookies from 'js-cookie'
import config from 'config'

export const KEY = 'cookie'

const cookiesParsed = (cookies) => {
  return cookies.split(';')
    .map(cookie => cookie.split('='))
    .reduce((acc, cookie) => {
      acc[decodeURIComponent(cookie[0].trim())] = decodeURIComponent(cookie[1].trim())
      return acc
    }, {})
}

export const CookieModule: StorefrontModule = async ({ store }) => {
  store.registerModule(KEY, cookieStore)
  if (isServer) {
    AsyncDataLoader.push({
      execute: async ({ context }) => {
        const cookieObject = context.server.request.headers.cookie
          ? cookiesParsed(context.server.request.headers.cookie)
          : null
        store.commit(`cookie/${SET_AGE_CONFIRMED}`, cookieObject ? !!cookieObject.ageConfirmed : false);
        store.commit(`cookie/${SET_CURRENCY_COOKIE}`, cookieObject ? cookieObject.storeCode : config.defaultStoreCode);
      }
    })
  } else {
    store.commit(`cookie/${SET_AGE_CONFIRMED}`, !!Cookies.get('ageConfirmed'));
    store.commit(`cookie/${SET_CURRENCY_COOKIE}`, Cookies.get('storeCode') ? Cookies.get('storeCode') : config.defaultStoreCode);
  }
}
