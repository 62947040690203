import getters from './getters'
import actions from './actions'
import mutations from './mutations'
export const storeCreditsStore = {
  namespaced: true,
  state: {
    store_credits_transactions: [],
    store_credits_details: null,
    product_promo_text: null,
    cart_promo_text: null,
    rewardPoints: null,
    cart_earn_rate: null
  },
  getters,
  actions,
  mutations
}
