import RootState from '@vue-storefront/core/types/RootState'
import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import { EagleState } from '../types/eagleState'

export const module: Module<EagleState, RootState> = {
  namespaced: true,
  state: {
    storeCurrency: false
  },
  mutations,
  actions
}
