import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { SearchSuggestionsState } from '../types/searchSuggestionsState'
import { SET_SEARCH_SUGGESTIONS } from './mutation-types';

const actions: ActionTree<SearchSuggestionsState, RootState> = {
  setSearchSuggestions ({ commit }, suggestions) {
    const fields = ['description', 'name_with_brand']
    let suggestedStrings = []
    fields.forEach(field => {
      if (suggestions[field] && Array.isArray(suggestions[field][0].options) && suggestions[field][0].options.length > 0) {
        suggestions[field][0].options.forEach(
          el => suggestedStrings.indexOf(el.text) === -1 ? suggestedStrings.push(el.text) : null
        );
      }
    })
    commit(SET_SEARCH_SUGGESTIONS, suggestedStrings)
  }
}

export default actions
