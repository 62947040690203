import Vue from 'vue'

export const setRequestCacheTags = ({ products = [] }) => {
  if (Vue.prototype.$cacheTags) {
    products.forEach((product) => {
      Vue.prototype.$cacheTags.add(`P${product.id}`);
      // Add parent product cache tags if present
      if (product.parentId && product.parentId !== product.id) {
        Vue.prototype.$cacheTags.add(`P${product.parentId}`);
      }
    })
  }
}
