import { MutationTree } from 'vuex'
import { EagleState } from '../types/eagleState'
import { SET_STORE_CURRENCY } from './mutation-types'

const mutations: MutationTree<EagleState> = {
  [SET_STORE_CURRENCY] (state, currency) {
    state.storeCurrency = currency
  }
}

export default mutations
