import { Module } from 'vuex'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'
import RootState from '@vue-storefront/core/types/RootState'
import merge from 'lodash-es/merge'
import actions from './actions'
import { stockModule } from '@vue-storefront/core/modules/catalog/store/stock'

const stockExtendedModule = merge(stockModule, {
  actions
})

export const stockNewModule: Module<ProductState, RootState> = stockExtendedModule
