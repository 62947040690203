import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { CookieState } from '../types'

const getters: GetterTree<CookieState, RootState> = {
  getAgeConfirmed: state => state.ageConfirmed,
  getCurrencyCookie: state => state.currencyCode
}

export default getters
