import { Module } from 'vuex'
import RootState from 'core/types/RootState'
import EWayState from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export const module: Module<EWayState, RootState> = {
  namespaced: true,
  state: {
    securedCardData: null,
    isValid: false,
    userSavedCards: null,
    userDefaultCard: null,
    selectedCardId: null
  },
  actions,
  mutations,
  getters
}
