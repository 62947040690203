import { Module } from 'vuex'
import CategoryState from '@vue-storefront/core/modules/catalog-next/store/category/CategoryState'
import RootState from '@vue-storefront/core/types/RootState'
import merge from 'lodash/merge'
import actions from './actions'
import getters from './getters'
import { categoryModule } from '@vue-storefront/core/modules/catalog-next/store/category'

const categoryExtendedModule = merge(categoryModule, {
  state: {
    searchQueryCategories: [],
    minPrice: 0,
    maxPrice: 0,
    colourAttributes: []
  },
  mutations: {
    CATEGORY_SET_SEARCH_QUERY_CATS (state, categories) {
      state.searchQueryCategories = categories
    },
    SET_MAX_PRICE (state, price) {
      state.maxPrice = price
    },
    SET_MIN_PRICE (state, price) {
      state.minPrice = price
    },
    SET_COLOUR_ATTRIBUTES (state, attributes) {
      state.colourAttributes = attributes
    }
  },
  getters,
  actions
})

export const categoryNextExtended: Module<CategoryState, RootState> = categoryExtendedModule
