import { GetterTree } from 'vuex';
import CategoryState from '@vue-storefront/core/modules/catalog-next/store/category/CategoryState';
import RootState from '@vue-storefront/core/types/RootState';

const getters: GetterTree<CategoryState, RootState> = {
  getSearchQueryCategories: state => (state as any).searchQueryCategories,
  getColourAttributes: state => (state as any).colourAttributes
}

export default getters
