import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { CompareModule } from '@vue-storefront/core/modules/compare'
import { WishlistModule } from '@vue-storefront/core/modules/wishlist'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from '@vue-storefront/core/modules/user'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { GoogleAnalyticsModule } from './google-analytics';
import { GoogleTagManagerModule } from './google-tag-manager';
import { ConfigurableProductsModule } from './configurable-products'
// import { AmpRendererModule } from './amp-renderer';
import { PaymentBackendMethodsModule } from './payment-backend-methods'
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { InitialResourcesModule } from '@vue-storefront/core/modules/initial-resources'
import { ReviewModule } from '@vue-storefront/core/modules/review'
import { KlaviyoModule } from './vsf-klaviyo';
import { AheadworksBlog } from './aheadworks-blog'

import { DeviceModule } from './device';
import { registerModule } from '@vue-storefront/core/lib/modules'
import { CookieModule } from './cookie'
import { CartExtended } from './cart-extended'
import { CheckoutExtended } from './checkout-extended'
import { ProductExtended } from './product-extended'
import { CategoryNextExtended } from './category-next-extended'
import { StoreCreditsModule } from './store-credits'
import { AddressBook } from './address-book'
import { MagentoMailerModule } from './magento-mailer'
import { OutOfStockNotif } from './out-of-stock-notif'
import { CountryDataModule } from './country-data'
import { EagleLeather } from './eagle-leather'
import { PaymentAfterpay } from './payment-afterpay'
import { PaymentEway } from './payment-eway'
import { SearchSuggestions } from './search-suggestions'
import { UserExtended } from './user-extended'
import { MagentoConfig } from './magento-config'
import { OrderExtended } from './order-extended'
import { UrlExtended } from './url-extended'
import { UrlRewrites } from './url-rewrites'
import { PosthogModule } from './posthog'
import { StockExtended } from './stock-extended'
import { NewsletterExtended } from './newsletter-extended';
import { MagebitPostCodes } from './magebit-postcodes'
import { GoogleRecaptcha } from './google-recaptcha'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(WishlistModule) // Trigger on wishlist icon click
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(OrderModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(CompareModule)
  registerModule(BreadcrumbsModule)
  registerModule(GoogleAnalyticsModule)
  registerModule(PosthogModule)
  // registerModule(AmpRendererModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(NewsletterExtended)
  registerModule(InitialResourcesModule)
  registerModule(DeviceModule)
  registerModule(KlaviyoModule)
  registerModule(AheadworksBlog)
  registerModule(CartExtended)
  registerModule(CheckoutExtended)
  registerModule(ProductExtended)
  registerModule(CookieModule)
  registerModule(CategoryNextExtended)
  registerModule(StoreCreditsModule)
  registerModule(MagentoMailerModule)
  registerModule(OutOfStockNotif)
  registerModule(CountryDataModule)
  registerModule(EagleLeather)
  registerModule(PaymentAfterpay)
  registerModule(PaymentEway)
  registerModule(SearchSuggestions)
  registerModule(UserExtended)
  registerModule(MagentoConfig)
  registerModule(GoogleTagManagerModule)
  registerModule(OrderExtended)
  registerModule(UrlExtended)
  registerModule(UrlRewrites)
  registerModule(ReviewModule)
  registerModule(ConfigurableProductsModule)
  registerModule(StockExtended)
  registerModule(MagebitPostCodes)
  registerModule(GoogleRecaptcha)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
  AddressBook
]
