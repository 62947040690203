import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { beforeRegistration } from './hooks/beforeRegistration'
import { afterRegistration } from './hooks/afterRegistration'

export const KEY = 'payment-eway'
export const EWAY_PAYMENT_CODE = 'ewayrapid'

export const PaymentEway: StorefrontModule = function ({ store, appConfig }) {
  beforeRegistration(appConfig, store)
  store.registerModule(KEY, module)
  afterRegistration(appConfig, store)
}
