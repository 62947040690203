import { initCacheStorage } from '@vue-storefront/core/lib/storage-manager'
import { createModule } from '@vue-storefront/core/lib/module'
import { module } from './store'

export const KEY = 'addressBook'
export const cacheStorage = initCacheStorage(KEY)
export const AddressBook = createModule({
  key: KEY,
  store: { modules: [ { key: KEY, module } ] }
})
