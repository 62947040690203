import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Logger } from '@vue-storefront/core/lib/logger';
import { isServer } from '@vue-storefront/core/helpers';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import RootState from 'core/types/RootState';
import { Store } from 'vuex';
import { trackEvents } from './trackEvents';
import Vue from 'vue'

type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export const POSTHOG_STATE_KEY = 'posthog'

export interface PosthogConfig {
  enabled?: boolean,
  trackEvents?: boolean,
  key: string,

  // See PosthogConfig in posthog-js
  recorderConfig: any
}

const getConfig = (config: { posthog?: Partial<PosthogConfig> }): PosthogConfig | undefined => {
  if (!config.posthog) {
    Logger.error('Posthog is not configured', 'posthog')()
    return
  }

  if (!config.posthog.enabled) {
    return
  }

  if (!config.posthog.recorderConfig?.api_host) {
    Logger.error('Posthog host is not configured', 'posthog')()
    return
  }

  if (!config.posthog.key) {
    Logger.error('Posthog key is not configured', 'posthog')()
    return
  }

  return config.posthog as PosthogConfig
}

export const loadPosthog = async () => {
  const posthog = await import(/* webpackChunkName: "posthog" */ 'posthog-js')
    .then(mod => mod.default)

  return posthog
}

export type Posthog = Awaited<ReturnType<typeof loadPosthog>>

const identifyUser = (posthog: Posthog, store: Store<RootState>) => {
  if (store.state.user.current) {
    const user = {
      firstname: store.state.user.current.firstname,
      lastname: store.state.user.current.lastname,
      email: store.state.user.current.email
    }

    Logger.info('Identified user', 'posthog', user)()
    posthog.identify(store.state.user.current.id)
    posthog.people.set(user)
  }
}

export const PosthogModule: StorefrontModule = async ({ store, appConfig }) => {
  const config = getConfig(appConfig)

  if (!config || isServer) {
    // Not configured or disabled
    return
  }

  const posthog = await loadPosthog()

  Logger.info('Initializing posthog', 'posthog')()

  posthog.init(config.key, {
    ...config.recorderConfig
  })

  // Make posthog available in other places
  Vue.prototype.$posthog = posthog

  identifyUser(posthog, store)

  EventBus.$on('user-after-loggedin', () => {
    identifyUser(posthog, store)
  })

  if (config.trackEvents) {
    trackEvents(posthog, store)
  }
}
