import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import ConfigState from '../types/ConfigState'

const mutations: MutationTree<ConfigState> = {
  [types.UPDATE_CONFIG_LIST] (state, items) {
    const stateList = Object.assign({}, state.list)
    items.forEach(item => { stateList[item.path] = item.value })
    state.list = stateList
  }
}

export default mutations
