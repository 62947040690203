import { PostCodesService } from '../data-resolver/PostCodesService'
import { ActionTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState'
import State from '../types/state'
import * as MutationTypes from '../types/mutation-types'

const actions: ActionTree<State, RootState> = {
  loadPostCodes: async ({ state, commit }) => {
    commit(MutationTypes.SET_MODULE_LOADED, false)
    const { result, resultCode } = await PostCodesService.loadPostCodes('')

    if (result.length) {
      const states = []
      const cities = []
      const codes = []

      result.forEach(data => {
        if (!codes?.[data.postcode]) {
          codes[data.postcode] = []
        }

        if (!cities?.[data.locality]) {
          cities[data.locality] = []
        }

        if (!states?.[data.state]) {
          states[data.state] = []
        }

        if (data.postcode && data.locality && data.state) {
          codes[data.postcode].push({
            locality: data.locality,
            state: data.state
          })
          cities[data.locality].push({
            postcode: data.postcode,
            state: data.state
          })
          states[data.state].push({
            postcode: data.postcode,
            locality: data.locality
          })
        }
      })

      commit(MutationTypes.SET_CITIES, cities)
      commit(MutationTypes.SET_CODES, codes)
      commit(MutationTypes.SET_STATES, states)
    }
    commit(MutationTypes.SET_MODULE_LOADED, true)
  }
}

export default actions
