import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import i18n from '@vue-storefront/core/i18n'
import { SearchQuery } from 'storefront-query-builder'

export const productFilterChangeByManyOptions = async (filterOption, store, router) => {
  EventBus.$emit('product-before-configure', { filterOption: filterOption, configuration: store.getters['product/getCurrentProductConfiguration'] })

  let searchQuery = new SearchQuery()
  searchQuery = searchQuery.applyFilter({ key: 'sku', value: { 'eq': store.getters['product/getCurrentProduct'].parentSku } })
  const { items: [newProductVariant] } = await store.dispatch('product/findProducts', {
    query: searchQuery,
    size: 1,
    configuration: filterOption,
    options: {
      fallbackToDefaultWhenNoAvailable: false,
      setProductErrors: true,
      assignProductConfiguration: true,
      separateSelectedVariant: true
    }
  }, { root: true })

  const { configuration, selectedVariant, options, product_option } = newProductVariant

  if (selectedVariant) {
    const newProductConfiguration = Object.assign(
      {},
      store.getters['product/getCurrentProduct'],
      selectedVariant,
      { configuration, options, product_option }
    )

    await store.dispatch('product/setCurrent', newProductConfiguration)

    EventBus.$emit('product-after-configure', { product: newProductConfiguration, configuration: configuration, selectedVariant: selectedVariant })
    return selectedVariant
  } else {
    store.dispatch('notification/spawnNotification', {
      type: 'warning',
      message: i18n.t(
        'No such configuration for the product. Please do choose another combination of attributes.'
      ),
      action1: { label: i18n.t('OK') }
    })
  }
}
