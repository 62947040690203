import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import WishlistState from '../types/WishlistState'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { doPlatformPricesSync } from '@vue-storefront/core/modules/catalog/helpers'

const actions: ActionTree<WishlistState, RootState> = {
  clear (context) {
    context.commit(types.WISH_DEL_ALL_ITEMS, [])
  },
  async load ({ commit, getters, dispatch }, force: boolean = false) {
    if (!force && getters.isWishlistLoaded) return
    const storedItems = await dispatch('loadFromCache')
    // sync prices, since they aren't matching across storeviews
    if (force && storedItems) await doPlatformPricesSync(storedItems, { forceSync: true, isWishlist: true })
    commit(types.WISH_LOAD_WISH, storedItems)
    commit(types.SET_WISHLIST_LOADED)
  },
  loadFromCache () {
    const wishlistStorage = StorageManager.get('wishlist')
    return wishlistStorage.getItem('current-wishlist')
  },
  addItem ({ commit }, product) {
    commit(types.WISH_ADD_ITEM, { product })
  },
  removeItem ({ commit }, product) {
    commit(types.WISH_DEL_ITEM, { product })
  }
}

export default actions
