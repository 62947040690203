import { Logger } from '@vue-storefront/core/lib/logger'
import { isServer } from '@vue-storefront/core/helpers'

declare global {
  interface Window { grecaptcha: any}
}

export function afterRegistration (config, store): any {
  if (!config.googleRecaptcha || !config.googleRecaptcha.is_active || !config.googleRecaptcha.tokens) {
    if (!config.googleRecaptcha.is_active) {
      Logger.warn('Google captcha module is disabled.', 'Google Recaptcha')()
      return;
    }
    if (!config.googleRecaptcha.tokens || !config.googleRecaptcha.tokens.site_key || !config.googleRecaptcha.tokens.secret_key) {
      Logger.warn('No google captcha config or captcha site_key or captcha secret_key found.', 'Google Recaptcha')()
      return;
    }
  }

  if (!isServer && typeof window !== 'undefined') {
    let head = window.document.head
    let script = window.document.createElement('script');
    script.type = 'text/javascript'
    script.async = true
    script.src = '//www.google.com/recaptcha/api.js?render=' + config.googleRecaptcha.tokens.site_key
    head.appendChild(script)
  }
}
