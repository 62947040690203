import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import RootState from 'core/types/RootState'
import { Posthog } from '.'
import { cartHooks } from '@vue-storefront/core/modules/cart/hooks'

export const trackEvents = (posthog: Posthog, store: Store<RootState>) => {
  const storeView = currentStoreView()
  const currencyCode = storeView.i18n.currencyCode

  cartHooks.beforeSync((data) => {
    posthog.capture('before_sync', data)
    return data
  })

  cartHooks.afterSync((data) => {
    posthog.capture('after_sync', data)
    return data
  })

  store.subscribe(({ type, payload }, state) => {
    if (type === 'cart/cart/DEL') {
      posthog.capture('remove_from_cart', {
        currency: currencyCode,
        value: parseFloat(payload.product.price_incl_tax.toFixed(2)),
        items: [
          {
            item_id: payload.product.sku,
            item_name: payload.product.name,
            price: payload.product.price_incl_tax.toFixed(2),
            quantity: payload.product.qty,
            item_category: payload.product.category ? payload.product.category[0].name : ''
          }
        ]
      });
    }

    if (type === 'recently-viewed/recently-viewed/ADD') {
      posthog.capture('view_item', {
        currency: currencyCode,
        value: parseFloat(payload.product.price_incl_tax.toFixed(2)),
        items: [
          {
            item_id: payload.product.sku,
            item_name: payload.product.name,
            price: payload.product.price_incl_tax.toFixed(2),
            item_category: payload.product.category ? payload.product.category[0].name : ''
          }
        ]
      });
    }
    if (type === 'category-next/category/SET_PRODUCTS' || type === 'category-next/category/ADD_PRODUCTS') {
      posthog.capture('view_item_list', {
        items: payload.map(item => ({
          item_id: item.sku,
          item_name: item.name,
          price: item.price_incl_tax.toFixed(2)
        }))
      });
    }
  })

  EventBus.$on('order-after-placed', (event) => {
    posthog.capture('purchase', {
      order: event.order
    })
  })

  EventBus.$on('cart-before-add', (event) => {
    posthog.capture('add_to_cart', {
      currency: currencyCode,
      value: parseFloat(event.product.price_incl_tax.toFixed(2)),
      items: [
        {
          item_id: event.product.sku,
          item_name: event.product.name,
          price: event.product.price_incl_tax.toFixed(2),
          quantity: event.product.qty,
          item_category: event.product.category ? event.product.category[0].name : ''
        }
      ]
    });
  })
}
