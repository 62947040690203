import StoreCreditsState from '../types/StoreCreditsState'
import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import Transactions from '../types/Transactions'
import StoreCreditsDetails from '../types/StoreCreditsDetails'
import ProductPromoText from '../types/ProductPromoText'
import CartPromoText from '../types/CartPromoText'

const mutations: MutationTree<StoreCreditsState> = {
  [types.USER_TRANSACTIONS_HISTORY_LOADED] (state, transactions: Transactions[]) {
    state.store_credits_transactions = transactions
  },
  [types.USER_STORE_CREDITS_DETAILS_LOADED] (state, store_credits_details: StoreCreditsDetails) {
    state.store_credits_details = store_credits_details
  },
  [types.PRODUCT_PROMO_TEXT_LOADED] (state, product_promo_text: ProductPromoText) {
    state.product_promo_text = product_promo_text
  },
  [types.CART_PROMO_TEXT_LOADED] (state, cart_promo_text: CartPromoText) {
    state.cart_promo_text = cart_promo_text
  },
  SET_REWARDS_POINTS (state, data) {
    state.rewardPoints = data
  },
  [types.CART_POINTS_EARN_RATE] (state, earn_rate) {
    state.cart_earn_rate = earn_rate
  }
}

export default mutations
