import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import RootState from '@vue-storefront/core/types/RootState'
import ReviewState from '../types/ReviewState'
import * as types from './mutation-types'
import i18n from '@vue-storefront/i18n'
import Review from '@vue-storefront/core/modules/review/types/Review'
import { createLoadReviewsQuery } from '@vue-storefront/core/modules/review/helpers'
import { ReviewsService } from '@vue-storefront/core/data-resolver'
import config from 'config';

const actions: ActionTree<ReviewState, RootState> = {
  async list (context, { productId, approved = true, start = 0, size = 50, entityType = 'review', sort = '', excludeFields = null, includeFields = null }) {
    const query = createLoadReviewsQuery({ productId, approved })

    // check for reviews in default store
    const reviewResponse = await quickSearchByQuery({ query, start, size, entityType, sort, storeCode: config?.defaultStoreCode || null, excludeFields, includeFields })
    context.commit(types.REVIEW_UPD_REVIEWS, reviewResponse)
  },
  async add (context, review: Review) {
    EventBus.$emit('notification-progress-start', i18n.t('Adding a review ...'))

    // add default store id to save review in default store
    if (!review.stores?.length) {
      review.stores = [config.storeViews[config.defaultStoreCode]?.storeId]
    }

    const isReviewCreated = await ReviewsService.createReview(review)
    EventBus.$emit('notification-progress-stop')

    if (isReviewCreated) {
      EventBus.$emit('clear-add-review-form')
    }

    return isReviewCreated
  }
}

export default actions
