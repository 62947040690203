import RootState from '@vue-storefront/core/types/RootState'
import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { SearchSuggestionsState } from '../types/searchSuggestionsState'

export const module: Module<SearchSuggestionsState, RootState> = {
  namespaced: true,
  state: {
    suggestions: []
  },
  mutations,
  getters,
  actions
}
