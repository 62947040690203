import AfterPayState from './state'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'
import rootStore from '@vue-storefront/core/store'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const actions: ActionTree<AfterPayState, any> = {
  async process ({ dispatch, commit }) {
    EventBus.$emit('notification-progress-start')
    let customerToken = rootStore.getters['user/getToken']
    let quoteId = rootStore.getters['cart/getCartToken']
    const paymentData = rootStore.getters['checkout/getPaymentDetails']
    const payment = {
      'city': paymentData.city,
      'country_id': paymentData.country,
      'region_id': paymentData.region_id ? paymentData.region_id : 0,
      'firstname': paymentData.firstName,
      'lastname': paymentData.lastName,
      'telephone': paymentData.phoneNumber,
      'region': paymentData.state,
      'street': [paymentData.streetAddress, paymentData.apartmentNumber],
      'postcode': paymentData.zipCode
    }
    const response = await TaskQueue.execute({
      url: processLocalizedURLAddress(config.afterPay.endpoint.process),
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          quoteId: quoteId,
          payment: payment,
          token: customerToken
        })
      }
    })
    EventBus.$emit('notification-progress-stop')

    if (response.code === 200) {
      commit(types.SET_AFTERPAY_TOKEN, response.result)
      return response.result
    } else {
      Logger.error(response.result.errorMessage, 'AfterPay')()
      return response.result
    }
  },

  async confirm ({ dispatch, commit }, { responseData }) {
    EventBus.$emit('notification-progress-start')
    let customerToken = rootStore.getters['user/getToken']
    let quoteId = rootStore.getters['cart/getCartToken']

    const response = await TaskQueue.execute({
      url: processLocalizedURLAddress(config.afterPay.endpoint.complete),
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          status: responseData.status,
          orderToken: responseData.orderToken,
          quoteId: quoteId,
          token: customerToken
        })
      }
    })
    EventBus.$emit('notification-progress-stop')

    if (response.code === 200) {
      commit(types.SET_AFTERPAY_MESSAGE, response.result)
      return response.result
    } else {
      Logger.error(response.result.errorMessage, 'AfterPay')()
      // need to call message
      throw new Error(response.result.errorMessage)
    }
  }
}
