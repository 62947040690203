import { currentStoreView, localizedDispatcherRouteName, removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'
import { Payload } from '../types/Payload'

export const forBlog = async ({ dispatch }, payload: Payload) => {
  const { storeCode, appendStoreCode } = currentStoreView()
  const url = (removeStoreCodeFromRoute(payload.url.replace(/^\//, '')) as string)
  const POSTS_PER_PAGE = 10

  if (url === 'blog') {
    await dispatch('aheadworks-blog/loadCategoryPosts', {
      perPage: POSTS_PER_PAGE
    }, { root: true })
    return {
      name: localizedDispatcherRouteName('aheadworks-blog', storeCode, appendStoreCode)
    }
  }

  if (url.startsWith('blog/category')) {
    const match = url.match(/blog\/category\/([^/]+)\/?/)
    if (match) {
      const slug = match[1]

      const category = await dispatch('aheadworks-blog/loadCategory', {
        filters: {
          url_key: {
            eq: slug
          }
        }
      }, { root: true })

      if (category) {
        return {
          name: localizedDispatcherRouteName('aheadworks-blog-category', storeCode, appendStoreCode),
          params: {
            slug
          }
        }
      }
    }
  }

  if (url.startsWith('blog')) {
    const match = url.match(/blog\/([^/]+)\/?/)

    if (match) {
      const slug = match[1]

      const post = await dispatch('aheadworks-blog/loadPost', {
        filters: {
          url_key: slug
        }
      }, { root: true })

      if (post) {
        return {
          name: localizedDispatcherRouteName('aheadworks-blog-post', storeCode, appendStoreCode),
          params: {
            slug
          }
        }
      }
    }
  }

  return null
}
