import { ActionTree } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'
import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config'
import { registerProductsMapping } from '@vue-storefront/core/modules/catalog/helpers'
import { setRequestCacheTags } from 'src/modules/product-extended/helpers'
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import { canCache, getOptimizedFields, storeProductToCache } from '@vue-storefront/core/modules/catalog/helpers/search';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { prepareProducts } from '@vue-storefront/core/modules/catalog/helpers/prepare';
import { configureProducts } from '@vue-storefront/core/modules/catalog/helpers/configure';
import { getProductConfigurationOptions } from '@vue-storefront/core/modules/catalog/helpers/productOptions';
import * as types from '@vue-storefront/core/modules/catalog/store/product/mutation-types';
import validateProduct from 'src/modules/cart-extended/helpers/validateProduct';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { checkParentRedirection } from '@vue-storefront/core/modules/catalog/events'

const getProducts = async (context, {
  query,
  start = 0,
  size = 50,
  sort = '',
  excludeFields = null,
  includeFields = null,
  configuration = null,
  options: {
    prefetchGroupProducts = !isServer,
    fallbackToDefaultWhenNoAvailable = true,
    setProductErrors = false,
    setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
    filterUnavailableVariants = config.products.filterUnavailableVariants,
    assignProductConfiguration = false,
    separateSelectedVariant = false
  } = {}
}: DataResolver.ProductSearchOptions): Promise<DataResolver.ProductsListResponse> => {
  const cacheTags = []
  const isCacheable = canCache({ includeFields, excludeFields })
  const { excluded, included } = getOptimizedFields({ excludeFields, includeFields })
  const appliedFilters = query.getAppliedFilters()
  const categoryFilter = appliedFilters.find(({ attribute }) => attribute === 'category_ids')

  if (query && query.getSearchText()) {
    let searchString = query.getSearchText().toString().toLowerCase()
    if (searchString in config.elasticsearch.suggestionMap) {
      searchString = config.elasticsearch.suggestionMap[searchString]
      query.setSearchText(searchString)
    }
    query = query.applyFilter({
      key: 'suggestions',
      value: { 'text': searchString }
    })
  }

  if (categoryFilter && categoryFilter.value) {
    Object.values(categoryFilter.value).forEach(categoryId => {
      cacheTags.push(`C${categoryId}`)
    })
  }

  let {
    items: products = [],
    attributeMetadata = [],
    aggregations = [],
    total,
    perPage,
    suggestions
  } = await quickSearchByQuery({
    query,
    start,
    size,
    entityType: 'product',
    sort,
    excludeFields: excluded,
    includeFields: included,
    cacheTags
  })

  if (suggestions) {
    await context.dispatch('search-suggestions/setSearchSuggestions', suggestions, { root: true })
  }

  products = prepareProducts(products)

  for (let product of products) { // we store each product separately in cache to have offline access to products/single method
    if (isCacheable) { // store cache only for full loads
      storeProductToCache(product, 'sku')
    }
  }

  const configuredProducts = await configureProducts({
    products,
    attributes_metadata: attributeMetadata,
    configuration,
    options: {
      prefetchGroupProducts,
      fallbackToDefaultWhenNoAvailable,
      setProductErrors,
      setConfigurableProductOptions,
      filterUnavailableVariants,
      assignProductConfiguration,
      separateSelectedVariant
    },
    excludeFields: excluded,
    includeFields: included
  })

  return {
    items: configuredProducts,
    perPage,
    start,
    total,
    aggregations,
    attributeMetadata
  }
}

const updateProductData = (context, product) => {
  const { configuration, ...restProduct } = product
  const productUpdated = Object.assign({}, restProduct)
  if (!config.products.gallery.mergeConfigurableChildren) {
    context.dispatch('setProductGallery', { product: productUpdated })
  }
  const productOptions = getProductConfigurationOptions({ product, attribute: context.rootState.attribute })
  context.commit(types.PRODUCT_SET_CURRENT_OPTIONS, productOptions)
  context.commit(types.PRODUCT_SET_CURRENT_CONFIGURATION, configuration || {})
  context.commit(types.PRODUCT_SET_CURRENT, productUpdated)
  return productUpdated
}

const actions: ActionTree<ProductState, RootState> = {
  async findProducts (context, {
    query,
    start = 0,
    size = 50,
    sort = '',
    excludeFields = null,
    includeFields = null,
    configuration = null,
    populateRequestCacheTags = false,
    options: {
      populateRequestCacheTags: populateRequestCacheTagsNew = false,
      prefetchGroupProducts = !isServer,
      setProductErrors = false,
      fallbackToDefaultWhenNoAvailable = true,
      assignProductConfiguration = false,
      separateSelectedVariant = false,
      setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
      filterUnavailableVariants = config.products.filterUnavailableVariants
    } = {}
  } = {}) {
    const { items, ...restResponseData } = await getProducts(context, {
      query,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      options: {
        prefetchGroupProducts,
        fallbackToDefaultWhenNoAvailable,
        setProductErrors,
        setConfigurableProductOptions,
        filterUnavailableVariants,
        assignProductConfiguration,
        separateSelectedVariant
      }
    })

    registerProductsMapping(context, items)

    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }

    if (populateRequestCacheTags || populateRequestCacheTagsNew) {
      setRequestCacheTags({ products: items })
    }

    const newProducts = await context.dispatch('tax/calculateTaxes', { products: items }, { root: true })

    return { ...restResponseData, items: newProducts }
  },
  /**
   * Set current product with given variant's properties
   * @param {Object} context
   * @param {Object} productVariant
   */
  async setCurrent (context, product) {
    if (product && typeof product === 'object') {
      let errors = validateProduct(product)
      // Check for validation errors and if invalid data detected attempt to fix them
      if (errors && Array.isArray(errors) && errors.length > 0) {
        Logger.error('Product (' + (product.sku || '') + ') validation error: ' + errors[0], 'validation')()
        const parentSku = product.parentSku
        const productSku = product.sku
        if (productSku) {
          // Fetch product data
          product = await context.dispatch('single', {
            options: {
              sku: parentSku || product.sku,
              childSku: product.sku
            },
            key: 'sku',
            skipCache: true
          })

          // If parent product is detected that was missing, re-fetch data including default parent SKU
          if (!parentSku && product?.default_parent) {
            Logger.error('Product (' + productSku + ') validation error: Missing parent SKU', 'validation')()
            product = await context.dispatch('single', {
              options: {
                sku: product.default_parent,
                childSku: product.sku
              },
              key: 'sku',
              skipCache: true
            })
          }

          // Re-validate product data and update state if data fix was successful
          errors = validateProduct(product)
          if (!errors || (Array.isArray(errors) && errors.length < 1)) {
            Logger.error(
              'Product (' + productSku + ') data were fixed.',
              'validation'
            )()
            return updateProductData(context, product)
          } else {
            Logger.error(
              'Product (' + productSku + ') final validation error: ' + (errors[0] || 'undefined'),
              'validation'
            )()
          }
        } else {
          Logger.error('Product validation error: missing SKU.', 'validation')()
        }
      } else {
        // No validation errors was encountered proceeding with normal workflow
        return updateProductData(context, product)
      }
      Logger.error('Unable to update current product.', 'product')()
    }
  },
  /**
   * Load the product data and sets current product
   * (Use customized setRequestCacheTags logic)
   */
  async loadProduct ({ dispatch, state }, { parentSku, childSku = null, route = null, skipCache = false }) {
    Logger.info('Fetching product data asynchronously', 'product', { parentSku, childSku })()
    EventBus.$emit('product-before-load', { store: rootStore, route: route })

    const product = await dispatch('single', {
      options: {
        sku: parentSku,
        childSku: childSku
      },
      key: 'sku',
      skipCache
    })

    setRequestCacheTags({ products: [product] })

    await dispatch('setCurrent', product)

    if (product.status >= 2) {
      throw new Error(`Product query returned empty result product status = ${product.status}`)
    }
    if (product.visibility === 1) { // not visible individually (https://magento.stackexchange.com/questions/171584/magento-2-table-name-for-product-visibility)
      if (config.products.preventConfigurableChildrenDirectAccess) {
        const parentProduct = await dispatch('findConfigurableParent', { product })
        checkParentRedirection(product, parentProduct)
      } else {
        throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
      }
    }

    if (config.entities.attribute.loadByAttributeMetadata) {
      await dispatch('attribute/loadProductAttributes', { products: [product] }, { root: true })
    } else {
      await dispatch('loadProductAttributes', { product })
    }

    const syncPromises = []
    const gallerySetup = dispatch('setProductGallery', { product })
    if (isServer) {
      syncPromises.push(gallerySetup)
    }
    await Promise.all(syncPromises)
    await EventBus.$emitFilter('product-after-load', { store: rootStore, route: route })
    return product
  }
}

export default actions
