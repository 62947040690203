
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import connectActions from '@vue-storefront/core/modules/cart/store/actions/connectActions'
import couponActions from './couponActions'
import itemActions from './itemActions'
import mergeActions from '@vue-storefront/core/modules/cart/store/actions/mergeActions';
import methodsActions from '@vue-storefront/core/modules/cart/store/actions/methodsActions'
import productActions from '@vue-storefront/core/modules/cart/store/actions/productActions'
import quantityActions from '@vue-storefront/core/modules/cart/store/actions/quantityActions'
import synchronizeActions from '@vue-storefront/core/modules/cart/store/actions/synchronizeActions'
import totalsActions from '@vue-storefront/core/modules/cart/store/actions/totalsActions'

const actions: ActionTree<CartState, RootState> = {
  ...connectActions,
  ...itemActions,
  ...couponActions,
  ...mergeActions,
  ...methodsActions,
  ...productActions,
  ...quantityActions,
  ...synchronizeActions,
  ...totalsActions
}

export default actions
