import { Module } from 'vuex'
import RootState from 'core/types/RootState'
import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import OutOfStockNotifState from './state'

export const module: Module<OutOfStockNotifState, RootState> = {
  namespaced: true,
  state: {
    productId: null,
    customerEmail: null
  },
  actions: {
    async subscribe ({ dispatch, commit }, { productId, guestEmail, customerId, parentId, storeId = currentStoreView().storeId }) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.stock_notification.endpoint),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ productId, guestEmail, customerId, parentId, storeId })
        }
      })
      if (response.code === 200) {
      } else {
        throw new Error(response.result.errorMessage)
      }
      return response
    },
    async checkSubscribe ({ dispatch, commit }, { productId, guestEmail, customerId, parentId, storeId = currentStoreView().storeId }) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.stock_notification.endpoint_check),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ productId, guestEmail, customerId, parentId, storeId })
        }
      })
      if (response.code === 200) {
      } else {
        throw new Error(response.result.errorMessage)
      }
      return response
    },
    setProductId ({ commit, rootState }, data) {
      commit('SELECT_PRODUCT_ID', data)
    },
    setCustomerEmail ({ commit, rootState }, data) {
      commit('SELECT_CUSTOMER_EMAIL', data)
    }
  },
  mutations: {
    SELECT_PRODUCT_ID (state, data) {
      state.productId = data
    },
    SELECT_CUSTOMER_EMAIL (state, data) {
      state.customerEmail = data
    }
  }
}
