import { ActionTree } from 'vuex'
import { StoreCreditsDataResolver } from '../data-resolver/store-credits-service'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import StoreCreditsState from '../types/StoreCreditsState'

const actions: ActionTree<StoreCreditsState, RootState> = {

  async loadTransactionHistory (context) {
    let customerToken = rootStore.getters['user/getToken']

    const resp = await StoreCreditsDataResolver.getTransactionHistory(customerToken)

    if (resp.code === 200) {
      context.commit(types.USER_TRANSACTIONS_HISTORY_LOADED, resp.result)
    }

    return resp.result
  },
  async loadProductPromoText (context, productId) {
    let customerToken = rootStore.getters['user/getToken']
    const resp = await StoreCreditsDataResolver.getProductPromoText(productId, customerToken)

    if (resp.code === 200) {
      context.commit(types.PRODUCT_PROMO_TEXT_LOADED, resp.result)
    }

    return resp.result
  },
  async loadCartPromoText (context, cartId) {
    const resp = await StoreCreditsDataResolver.getCartPromoText(cartId)

    if (resp.code === 200) {
      context.commit(types.CART_PROMO_TEXT_LOADED, resp.result)
    }

    return resp.result
  },
  async loadStoreCreditsDetails (context) {
    let customerToken = rootStore.getters['user/getToken']

    const resp = await StoreCreditsDataResolver.getStoreCreditsDetails(customerToken)
    if (resp.code === 200) {
      context.commit(types.USER_STORE_CREDITS_DETAILS_LOADED, resp.result)
    }

    return resp.result
  },
  async loadCartEarnPoints (context) {
    const cartId = rootStore.getters['cart/getCartToken']
    const customerToken = rootStore.getters['user/getToken']

    const resp = await StoreCreditsDataResolver.loadEarnPoints(cartId, customerToken)

    if (resp.code === 200) {
      context.commit(types.CART_POINTS_EARN_RATE, resp.result)
    } else {
      context.commit(types.CART_POINTS_EARN_RATE, null)
    }

    return resp.result
  }
}

export default actions
