import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'

import { once, isServer } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Logger } from '@vue-storefront/core/lib/logger'

import { googleTagManagerModule } from './store'
import { afterRegistration, isEnabled } from './hooks/afterRegistration'

export const KEY = 'google-tag-manager'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const getGtmId = async function (store: Store<RootState>) {
  if (isServer) {
    return null;
  }

  // Make sure config is loaded
  await store.dispatch('magentoConfig/list', { path: null });

  return store.getters['magentoConfig/getValue']('google/gtm/container_id');
}

export const GoogleTagManagerModule: StorefrontModule = async function ({ store, router, appConfig }) {
  const tagManagerId = await getGtmId(store)

  if (isEnabled(tagManagerId)) {
    once('__VUE_EXTEND_GTM__', () => {
      Vue.use(VueGtm, {
        enabled: true,
        id: tagManagerId,
        debug: appConfig.googleTagManager.debug,
        vueRouter: router
      })
    })
  } else {
    Logger.warn('Google Tag Manager extensions is not working. Ensure Google Tag Manager container ID is defined in config', 'GTM')()
  }

  store.registerModule(KEY, googleTagManagerModule)

  afterRegistration(appConfig, store, tagManagerId)
}
