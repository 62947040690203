import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import UserState from 'core/modules/user/types/UserState'

interface ExtendedUserState extends UserState {
  reviewMode: boolean
}

const mutations: MutationTree<ExtendedUserState> = {
  [types.USER_REVIEW_MODE_CHANGED] (state, reviewMode) {
    state.reviewMode = reviewMode
  }
}

export default mutations
