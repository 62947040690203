import { isServer } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export function beforeRegistration (config, store) {
  if (!isServer && config.hasOwnProperty('afterPay') && window['AfterPay'] === undefined) {
    const storeView = currentStoreView()
    const { currencyCode } = storeView.i18n
    const merchantKey = config.afterPay.hasOwnProperty('merchant_key') ? config.afterPay.merchant_key : ''
    const env = config.afterPay.hasOwnProperty('env') ? config.afterPay.env : ''
    let sdkUrl = `https://portal.afterpay.com/afterpay.js?merchant_key=magento2`

    if (env === 'sandbox') {
      sdkUrl = `https://portal.sandbox.afterpay.com/afterpay.js?merchant_key=magento2`
    }

    // load script for checkout
    var script = document.createElement('script')
    script.setAttribute('src', sdkUrl)
    document.head.appendChild(script)

    // load script for productBlock
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      'https://js.afterpay.com/afterpay-1.x.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
