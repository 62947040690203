import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'
import { SearchQuery } from 'storefront-query-builder'

const loadProducts = (dispatch, list) => {
  const productIds = list.map(item => item.product_id)
  let query = new SearchQuery()
  query = query
    .applyFilter({ key: 'id', value: { 'in': productIds } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } })

  return dispatch('product/findProducts', { query: query }, { root: true })
}

export const Wishlist = {
  namespaced: true,
  state: {
    wishlist: []
  },
  mutations: {
    GET_WISHLIST (state, data) {
      state.wishlist = data || []
    }
  },
  actions: {
    async loadWishList ({ dispatch, rootState, commit }) {
      const { id: customerId } = Object.assign({}, rootState.user.current)
      const url: string = processLocalizedURLAddress(config.users.wishlist_endpoint).replace('{{customerId}}', customerId)

      const response = await TaskQueue.execute({
        url: url,
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      if (response.code === 200 && response.result) {
        commit('userWishlist/GET_WISHLIST', (await loadProducts(dispatch, response.result)), { root: true })
      } else {
        commit('userWishlist/GET_WISHLIST', [], { root: true })
      }
    },
    async addToWishList ({ dispatch, rootState, commit }, product) {
      const { id: customerId } = Object.assign({}, rootState.user.current)
      const url: string = processLocalizedURLAddress(config.users.wishlist_endpoint).replace('{{customerId}}', customerId)

      const response = await TaskQueue.execute({
        url: url,
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            item: {
              product: product,
              qty: 1
            }
          })
        }
      })

      if (response.code === 200 && response.result) {
        commit('userWishlist/GET_WISHLIST', (await loadProducts(dispatch, response.result)), { root: true })
      } else {
        commit('userWishlist/GET_WISHLIST', [], { root: true })
      }
    },
    async removeItem ({ rootState, commit, dispatch }, productId) {
      const { id: customerId } = Object.assign({}, rootState.user.current)
      const url: string = processLocalizedURLAddress(config.users.wishlist_endpoint + '/' + productId).replace('{{customerId}}', customerId)

      const response = await TaskQueue.execute({
        url: url,
        payload: {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      if (response.code === 200 && response.result) {
        commit('userWishlist/GET_WISHLIST', (await loadProducts(dispatch, response.result)), { root: true })
      } else {
        commit('userWishlist/GET_WISHLIST', [], { root: true })
      }
    }
  },
  getters: {
    getWishlist (state) {
      return state.wishlist.items && state.wishlist.items.length ? state.wishlist.items : null
    },
    isOnWishlist: state => product => {
      return state.wishlist.items && state.wishlist.items.length && product.sku ? state.wishlist.items.some(p => p.sku === product.sku || p.parentSku === product.sku) : false
    },
    getWishlistLength (state) {
      return state.wishlist.items ? state.wishlist.items.length : 0
    }
  }
}
