import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { CookieState } from '../types'
import { SET_AGE_CONFIRMED, SET_CURRENCY_COOKIE } from './mutation-types'
import Cookies from 'js-cookie'

const actions: ActionTree<CookieState, RootState> = {
  async setAgeConfirmed ({ commit }, ageConfirmed) {
    if (!ageConfirmed) {
      Cookies.remove('ageConfirmed')
    } else {
      Cookies.set('ageConfirmed', '1')
    }
    commit(SET_AGE_CONFIRMED, ageConfirmed)
  },
  async setCurrencyCookie ({ commit }, currency) {
    if (!currency) {
      Cookies.set('storeCode', 'default')
    } else {
      Cookies.set('storeCode', currency)
    }
    commit(SET_CURRENCY_COOKIE, currency)
  }
}

export default actions
