import { SearchQuery } from 'storefront-query-builder'

export const createLoadConfigsQuery = ({ path }) => {
  let query = new SearchQuery()

  if (path) {
    if (Array.isArray(path)) {
      query = query.applyFilter({ key: 'path', value: { 'in': path } })
    } else {
      query = query.applyFilter({ key: 'path', value: { 'eq': path } })
    }
  }
  return query
}
