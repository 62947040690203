import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import EWayState from './state'

const mutations: MutationTree<EWayState> = {
  [types.SET_SECURED_CARD_DATA] (state, data) {
    state.securedCardData = data
  },
  [types.SET_EWAY_VALID] (state, status) {
    state.isValid = status
  },
  [types.SET_USER_SAVED_CARDS] (state, status) {
    state.userSavedCards = status
  },
  [types.SET_USER_DEFAULT_CARD_ID] (state, status) {
    state.userDefaultCard = status
  },
  [types.SET_USER_SELECTED_CARD_ID] (state, status) {
    state.selectedCardId = status
  }
}
export default mutations
