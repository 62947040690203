import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from '../store/mutation-types'
import { VSF_AFTERPAY_CODE } from '../index';

export function afterRegistration (config, store) {
  let correctPaymentMethod = false

  const placeAfterPayOrder = () => {
    if (correctPaymentMethod) {
      store.commit(`payment-afterpay/${types.SET_BACKEND_PAYMENT_AFTERPAY}`, window['AfterPay'])
      EventBus.$emit('afterPay-process')
    }
  }

  if (!isServer) {
    // Register the handler for what happens when they click the place order button.
    EventBus.$on('checkout-before-placeOrder', placeAfterPayOrder)

    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      correctPaymentMethod = paymentMethodCode === VSF_AFTERPAY_CODE
    })
  }
}
