import RootState from 'core/types/RootState'
import { GetterTree } from 'vuex'
import StoreCreditsState from '../types/StoreCreditsState'

const getters: GetterTree<StoreCreditsState, RootState> = {
  getTransactions: state => state.store_credits_transactions,
  getStoreCreditsDetails: state => state.store_credits_details,
  getProductPromoText: state => state.product_promo_text,
  getCartPromoText: state => state.cart_promo_text,
  getRewardsPoints: state => state.rewardPoints,
  getEarnRate: state => state.cart_earn_rate
}

export default getters
