import { GetterTree } from 'vuex'
import RootState from 'core/types/RootState'
import ConfigState from '../types/ConfigState'
import { ConfigValueType } from '../types/ConfigValue'

const getters: GetterTree<ConfigState, RootState> = {
  getValue: state => (path: string, valueType: ConfigValueType = ConfigValueType.STRING) => {
    if (!state.list[path]) return null
    switch (valueType) {
      case ConfigValueType.STRING:
        return String(state.list[path])
      case ConfigValueType.ARRAY:
        return state.list[path].split(',')
      case ConfigValueType.JSON:
        return JSON.parse(state.list[path])
      default:
        return state.list[path]
    }
  }
}

export default getters
