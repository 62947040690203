import { prepareProducts } from '@vue-storefront/core/modules/catalog/helpers/prepare'
import { storeProductToCache } from '@vue-storefront/core/modules/catalog/helpers/search'
import * as categoryMutationTypes from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import * as cmsPageMutationTypes from '@vue-storefront/core/modules/cms/store/page/mutation-types'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { UrlState } from '@vue-storefront/core/modules/url/types/UrlState'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { createLoadingBlockQuery } from '@vue-storefront/core/modules/cms/helpers'
import { isServer } from '@vue-storefront/core/helpers'
import { forBlog } from '../builtin/forBlog'
import { removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'

export const actions: ActionTree<UrlState, RootState> = {
  async saveFallbackData ({ commit, dispatch }, { _type, _source }) {
    switch (_type) {
      case 'product': {
        const [product] = prepareProducts([_source])
        storeProductToCache(product, 'sku')
        break
      }
      case 'category': {
        commit('category-next/' + categoryMutationTypes.CATEGORY_ADD_CATEGORY, _source, { root: true })
        break
      }
      case 'cms_page': {
        // only prefetch for CSR, as ssr uses proper asyncdata fetch
        if (_source?.hasOwnProperty('prefetch_blocks') && !isServer) {
          // cached cms page cms widget sourcing
          await dispatch('prefetchCmsPageBlocks', { filterValues: _source.prefetch_blocks })
        }
        commit('cmsPage/' + cmsPageMutationTypes.CMS_PAGE_ADD_CMS_PAGE, _source, { root: true })
        commit('cmsPage/' + cmsPageMutationTypes.CMS_PAGE_SET_CURRENT, _source, { root: true })
        break
      }
      default: {
        break
      }
    }
  },
  async prefetchCmsPageBlocks ({ rootState, dispatch, commit }, { filterValues = null, filterField = 'identifier', size = 150, start = 0, excludeFields = null, includeFields = null }) {
    const blockResponse = await quickSearchByQuery({
      query: createLoadingBlockQuery({ filterField, filterValues }),
      entityType: 'cms_block',
      size,
      start,
      excludeFields,
      includeFields
    })

    if (blockResponse?.hasOwnProperty('items')) {
      // append blocks to state list, instead of replacing them
      blockResponse.items.forEach(block => {
        dispatch('cmsBlock/addItem', block, { root: true })
      })
    }
  },
  async mapFallbackUrl ({ dispatch }, { url, params }: { url: string, params: any}) {
    url = (removeStoreCodeFromRoute(url.startsWith('/') ? url.slice(1) : url) as string)

    // blog post preload
    if (url.startsWith('blog')) {
      const result = await forBlog({ dispatch }, { url, params })
      if (result) return result
    }

    // search for record in ES based on `url`
    const fallbackData = await dispatch('getFallbackByUrl', { url, params })

    // if there is record in ES then map data
    if (fallbackData) {
      const [result] = await Promise.all([
        dispatch('transformFallback', { ...fallbackData, params }),
        dispatch('saveFallbackData', fallbackData)
      ])
      return result
    }

    return {
      name: 'page-not-found',
      params: {
        slug: 'page-not-found'
      }
    }
  }
}

export default actions
