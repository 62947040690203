import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import State from '../types/state'
import actions from './actions'
import mutations from './mutations'

const postCodesStore: Module<State, RootState> = {
  namespaced: true,
  state: {
    postcodes: [],
    codes: {},
    cities: {},
    states: {},
    isModuleLoaded: false
  },
  actions,
  mutations,
  getters: {}
}

export default postCodesStore
