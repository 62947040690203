import { Module } from 'vuex'
import UserState from '@vue-storefront/core/modules/user/types/UserState'
import RootState from '@vue-storefront/core/types/RootState'
import merge from 'lodash-es/merge'
import actions from './actions'
import getters from './getters'
import { userStore } from '@vue-storefront/core/modules/user/store'
import mutations from './mutations'

const userExtendedModule = merge(userStore, {
  actions,
  getters,
  state: {
    reviewMode: false
  },
  mutations
})

export const userNewModule: Module<UserState, RootState> = userExtendedModule
