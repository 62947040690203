import { Module } from 'vuex'
import CheckoutState from '@vue-storefront/core/modules/checkout/types/CheckoutState'
import RootState from '@vue-storefront/core/types/RootState'
import merge from 'lodash-es/merge'
import actions from './actions'
import mutations from './mutations'
import { checkoutModule } from '@vue-storefront/core/modules/checkout/store/checkout'

const checkoutExtendedModule = merge(checkoutModule, {
  actions,
  mutations
})

export const checkoutNewModule: Module<CheckoutState, RootState> = checkoutExtendedModule
