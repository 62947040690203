import { processLocalizedURLAddress } from './../../../../core/helpers/index';
import Task from 'core/lib/sync/types/Task';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
}

const getTransactionHistory = async (customerToken: string): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.getTransactions)
    .replace('{{customerToken}}', customerToken)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

const getProductPromoText = async (productId: string, customerToken: string): Promise<Task> => {
  let url: string = processLocalizedURLAddress(config.storeCredits.endpoint.getProductPromoText)
    .replace('{{product}}', productId)
    .replace('{{customerToken}}', customerToken)

  // if (customerToken.length === 0) {
  //   url = url.replace('')
  // }

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

const getCartPromoText = async (cartId: string): Promise<Task> => {
  let url: string = processLocalizedURLAddress(config.storeCredits.endpoint.getCartPromoText)
    .replace('{{cart}}', cartId)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

export const getStoreCreditsDetails = async (customerToken: string): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.getStoreCreditsDetails)
    .replace('{{customerToken}}', customerToken)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

export const applyStoreCredits = async (data, pointsQty: string): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.applyStoreCredits)
    .replace('{{pointsQty}}', pointsQty)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'PUT',
      mode: 'cors',
      headers,
      body: JSON.stringify(data)
    }
  })
}

export const removeStoreCredits = async (data): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.removeStoreCredits)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'DELETE',
      mode: 'cors',
      headers,
      body: JSON.stringify(data)
    }
  })
}

const loadEarnPoints = async (cartId: string, customerToken): Promise<Task> => {
  let url: string = processLocalizedURLAddress(config.storeCredits.endpoint.loadPointsEarn)
    .replace('{{cart}}', cartId)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

export const StoreCreditsDataResolver = {
  getTransactionHistory,
  getProductPromoText,
  getStoreCreditsDetails,
  applyStoreCredits,
  removeStoreCredits,
  getCartPromoText,
  loadEarnPoints
}
