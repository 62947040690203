import { MutationTree } from 'vuex'
import { CookieState } from '../types'
import { SET_AGE_CONFIRMED, SET_CURRENCY_COOKIE } from './mutation-types'

const mutations: MutationTree<CookieState> = {
  [SET_AGE_CONFIRMED] (state, ageConfirmed) {
    state.ageConfirmed = ageConfirmed
  },
  [SET_CURRENCY_COOKIE] (state, currencyCode) {
    state.currencyCode = currencyCode
  }
}

export default mutations
