import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CheckoutState from '@vue-storefront/core/modules/checkout/types/CheckoutState'

const mutations: MutationTree<CheckoutState> = {
  [types.CHECKOUT_UPDATE_PD_PROP_VALUE] (state, payload) {
    state.personalDetails[payload[0]] = payload[1]
  }
}

export default mutations
