import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import IPostCodesService from '../types/DataResolver';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import config from 'config'

export const PostCodesService: IPostCodesService = {
  loadPostCodes: async () => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.postCodes, 'endpoint')),
      payload: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      },
      silent: true
    })
  }
}
