import { CartService } from '@vue-storefront/core/data-resolver'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { createDiffLog, notifications } from '@vue-storefront/core/modules/cart/helpers';
import validateProduct from 'src/modules/cart-extended/helpers/validateProduct'
import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config'

const itemActions = {
  async addItems ({ commit, dispatch, getters }, { productsToAdd, forceServerSilence = false }) {
    let productIndex = 0
    const diffLog = createDiffLog()
    for (let product of productsToAdd) {
      let attemptedFix = false;
      let errors = validateProduct(product)
      if (errors && Array.isArray(errors) && errors.length > 0 && product.sku) {
        const productSku = product.sku;
        Logger.error(
          'Cart Product (' + productSku + ') validation error: ' + errors[0],
          'validation'
        )()
        const parentSku = product.parentSku

        // Fetch product data
        product = await dispatch('product/single', {
          options: {
            sku: parentSku || product.sku,
            childSku: product.sku
          },
          key: 'sku',
          skipCache: true
        }, { root: true })

        // If parent product is detected that was missing, re-fetch data including default parent SKU
        if (!parentSku && product?.default_parent) {
          Logger.error(
            'Cart Product (' + productSku + ') validation error: Missing parent SKU',
            'validation'
          )()
          product = await dispatch('product/single', {
            options: {
              sku: product.default_parent,
              childSku: product.sku
            },
            key: 'sku',
            skipCache: true
          }, { root: true })
        }

        // Re-validate final product data
        errors = validateProduct(product)
        attemptedFix = true;
      }

      if (errors && Array.isArray(errors) && errors.length > 0) {
        Logger.error(
          'Cart Product (' + (product.sku || '') + ') final validation error: ' + errors[0],
          'validation'
        )()
      } else if (attemptedFix) {
        Logger.error(
          'Cart Product (' + product.sku + ') data were fixed.',
          'validation'
        )()
      }

      diffLog.pushNotifications(notifications.createNotifications({ type: 'error', messages: errors }))

      if (errors.length === 0) {
        const { status, onlineCheckTaskId } = await dispatch('checkProductStatus', { product })

        if (status === 'volatile' && !config.stock.allowOutOfStockInCart) {
          diffLog.pushNotification(notifications.unsafeQuantity())
        }
        if (status === 'out_of_stock') {
          diffLog.pushNotification(notifications.outOfStock())
        }

        if (status === 'ok' || status === 'volatile') {
          commit(types.CART_ADD_ITEM, {
            product: { ...product, onlineStockCheckid: onlineCheckTaskId }
          })
        }
        if (productIndex === (productsToAdd.length - 1) && (!getters.isCartSyncEnabled || forceServerSilence)) {
          diffLog.pushNotification(notifications.productAddedToCart())
        }
        productIndex++
      }
    }

    let newDiffLog = await dispatch('create')
    if (newDiffLog !== undefined) {
      diffLog.merge(newDiffLog)
    }

    if (getters.isCartSyncEnabled && getters.isCartConnected && !forceServerSilence) {
      const syncDiffLog = await dispatch('sync', { forceClientState: true })

      if (!syncDiffLog.isEmpty()) {
        diffLog.merge(syncDiffLog)
      }
    }

    return diffLog
  }
}

export default itemActions
