import RootState from 'core/types/RootState'
import { GetterTree } from 'vuex'
import EWayState from './state'

const getters: GetterTree<EWayState, RootState> = {
  getUserCards: state => state.userSavedCards,
  getUserDefaultCard: state => state.userDefaultCard,
  getUserSelectedCard: state => state.selectedCardId
}

export default getters
