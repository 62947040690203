import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '@vue-storefront/core/modules/user/types/UserState'
import i18n from '@vue-storefront/i18n'
import { UserService } from '@vue-storefront/core/data-resolver'
import { userHooksExecutors } from '@vue-storefront/core/modules/user/hooks'
import * as types from '@vue-storefront/core/modules/user/store/mutation-types'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { router } from '@vue-storefront/core/app'
import * as newTypes from './mutation-types'

const redirectOnCartDifference = ({ diffLog, serverItems, clientItems, hookResult, dryRun }, dispatch, commit) => {
  const hasServerItems = serverItems.length > 0
  const hasClientItems = clientItems.length > 0

  if (hasClientItems && hasServerItems) {
    const diffItems = diffLog.items
    let redirectStatus = false
    let serverHasNewItem = false
    let clientHasNewItem = false

    for (const item of diffItems) {
      if (item.status === 'wrong-qty') {
        redirectStatus = true
        break
      }

      if (item.status === 'no-item') {
        if (item.party === 'client') clientHasNewItem = true
        if (item.party === 'server') serverHasNewItem = true
      }

      if (clientHasNewItem && serverHasNewItem) {
        redirectStatus = true
        break
      }
    }

    if (redirectStatus) {
      commit(newTypes.USER_REVIEW_MODE_CHANGED, true)

      router.push({ name: 'cart' })
      dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Your guest cart and member cart have been merged. Please review the combined items in your cart.'),
        action1: { label: i18n.t('OK') },
        timeToLive: 10000
      }, { root: true })
    }
  }
}

const actions: ActionTree<UserState, RootState> = {
  async handleUpdateProfile ({ dispatch, rootState }, event) {
    if (event.resultCode === 200) {
      const currentPath = rootState.route.path
      if (currentPath !== '/rewards' && currentPath !== '/my-account/your-rewards') {
        dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t('Account data has successfully been updated'),
          action1: { label: i18n.t('OK') }
        }, { root: true })
      }
      dispatch('user/setCurrentUser', event.result, { root: true })
    }
  },
  /**
   * Login user and return user profile and current token
   */
  async login ({ commit, dispatch }, { username, password }) {
    await dispatch('resetUserInvalidation', {}, { root: true })
    const resp = await UserService.login(username, password)
    userHooksExecutors.afterUserAuthorize(resp)

    const cartDifferenceHandler = (cartDiff) => redirectOnCartDifference(cartDiff, dispatch, commit)

    EventBus.$on('servercart-after-diff', cartDifferenceHandler)
    if (resp.code === 200) {
      try {
        commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta }) // TODO: handle the "Refresh-token" header
        await dispatch('sessionAfterAuthorized', { refresh: true, useCache: false })
      } catch (err) {
        await dispatch('clearCurrentUser')
        EventBus.$off('servercart-after-diff', cartDifferenceHandler)
        throw new Error(err)
      }
    }
    EventBus.$off('servercart-after-diff', cartDifferenceHandler)

    EventBus.$emit('update-points')

    return resp
  }
}

export default actions
