import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import StockState from '@vue-storefront/core/modules/catalog/types/StockState'
import config from 'config'
import { StockService } from '@vue-storefront/core/data-resolver'
import { getStatus } from '@vue-storefront/core/modules/catalog/helpers/stock'

const actions: ActionTree<StockState, RootState> = {
  async check ({ dispatch }, { product }) {
    if (config.stock.synchronize) {
      const { result, task_id } = await StockService.check(product.sku)

      //* ** Update product stock qty for cart preorder box **/
      if (result && product.stock) {
        product.stock.qty = result.qty
        await dispatch('cart/updateItem', { product: product }, { root: true })
      }
      //* ****************************************************/

      return {
        qty: result ? result.qty : 0,
        status: getStatus(result, 'ok'),
        isManageStock: result.manage_stock,
        onlineCheckTaskId: task_id
      }
    }

    return {
      qty: product.stock ? product.stock.qty : 0,
      status: getStatus(product, 'volatile')
    }
  }
}

export default actions
