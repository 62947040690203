import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '@vue-storefront/core/modules/catalog-next/store/category/CategoryState'
import * as types from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { products, entities } from 'config'
import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { router } from '@vue-storefront/core/app'
import omit from 'lodash-es/omit'
import uniq from 'lodash-es/uniq'
import flatten from 'lodash-es/flatten'
import { SearchQuery, elasticsearch } from 'storefront-query-builder'
import bodybuilder from 'bodybuilder'
import config from 'config'
import Vue from 'vue'

const actions: ActionTree<CategoryState, RootState> = {
  async loadCategoryProducts ({ commit, getters, dispatch, rootState }, { route, category, pageSize = 50, query = null, isSearch = false } = {}) {
    const searchCategory = category || getters.getCategoryFrom(route.path) || {}
    const categoryMappedFilters = getters.getFiltersMap[searchCategory.id]
    const areFiltersInQuery = !!Object.keys(route[products.routerFiltersSource]).length
    if (!categoryMappedFilters && areFiltersInQuery) { // loading all filters only when some filters are currently chosen and category has no available filters yet
      await dispatch('loadCategoryFilters', searchCategory)
    }
    const searchQuery = getters.getCurrentFiltersFrom(route[products.routerFiltersSource], categoryMappedFilters)
    let filterQr = buildFilterProductsQuery(searchCategory, searchQuery.filters)
    // THIS IS CUSTOM FOR EAGLELEATHER
    if (query) {
      if (typeof query === 'string' && query.toLowerCase() in config.elasticsearch.suggestionMap) {
        query = config.elasticsearch.suggestionMap[query.toLowerCase()]
      }
      filterQr = filterQr.setSearchText(query)
    }

    // Apply category default VSF sorting from M2 if configured there
    let defaultSortOrder = products.defaultSortBy.order
    let defaultSortAttribute = products.defaultSortBy.attribute
    if (!isSearch && category && category.vsf_default_sort_by) {
      if (category.vsf_default_sort_by.indexOf(':') > 0) {
        const defaultSortArray = category.vsf_default_sort_by.split(':');
        defaultSortAttribute = defaultSortArray[0]
        defaultSortOrder = defaultSortArray[1]
      } else {
        defaultSortOrder = 'desc'
        defaultSortAttribute = category.vsf_default_sort_by
      }
    }

    if (!searchQuery?.sort) {
      // Apply custom sorting script for current category and position in it
      const customSortScript = `
        double pos = 0;
        boolean found = false;
        if (params._source.stock.is_in_stock == false) {
          return -2;
        }
        if (params._source.category != null) {
          for (def cat : params._source.category) {
            if (cat.category_id == ${searchCategory.id}) {
              pos = cat.position;
              found = true;
              break;
            }
          }
        }
        if (found) {
          return pos;
        } else {
          return -1;  // or any other fallback logic
        }
      `;

      filterQr = filterQr.applySort({
        field: '_script',
        options: {
          type: 'number',
          script: {
            source: customSortScript,
            lang: 'painless'
          },
          order: 'desc'
        }
      });
    }

    if (products.sortByStockStatus) {
      // on search page we don't need to sort by last updated
      searchQuery.sort
        ? Object.assign(searchQuery, { sort: `stock.is_in_stock:desc,${searchQuery.sort}` })
        : isSearch ? Object.assign(searchQuery, { sort: `stock.is_in_stock:desc,_score:desc` })
          : Object.assign(searchQuery, { sort: `stock.is_in_stock:desc,${defaultSortAttribute}:${defaultSortOrder}` })
    }
    // add support for sort options on search page
    if (searchQuery.sort) {
      const sortOptions = searchQuery.sort.split(',')
      sortOptions.forEach(sortoption => {
        const [ field, options ] = sortoption.split(':')
        filterQr = filterQr.applySort({ field, options })
      })
    } else if (query) {
      filterQr = filterQr.applySort({ field: '_score', options: 'desc' })
    }

    if (isSearch) {
      filterQr = filterQr.applyFilter({ key: 'suggestions', value: { 'text': query } })
    }

    if (
      route[products.routerFiltersSource].price
    ) {
      const priceFilter = route[products.routerFiltersSource].price
      const rangeqr = {}
      const filterValues = Array.isArray(priceFilter) ? priceFilter : [priceFilter]
      filterValues.forEach(singleFilter => {
        const [from, to] = singleFilter.split('-')
        rangeqr['gte'] = parseFloat(from)
        rangeqr['lte'] = parseFloat(to)
      })
      filterQr = filterQr.applyFilter({ key: 'price', value: rangeqr, scope: 'catalog' })
    }

    const cacheTags = []
    const appliedFilters = filterQr.getAppliedFilters()
    const categoryFilter = appliedFilters.find(({ attribute }) => attribute === 'category_ids')
    if (categoryFilter && categoryFilter.value) {
      Object.values(categoryFilter.value).forEach(categoryId => {
        cacheTags.push(`C${categoryId}`)
      })
    }

    // !-------------------------
    const { items, perPage, start, total, aggregations, suggestions } = await quickSearchByQuery({
      query: filterQr,
      sort: searchQuery.sort || `${defaultSortAttribute}:${defaultSortOrder}`,
      includeFields: entities.productList.includeFields,
      excludeFields: entities.productList.excludeFields,
      size: pageSize,
      start: route.query.page ? Math.max(pageSize * (route.query.page - 1), 0) : 0,
      cacheTags
    })

    await dispatch('tax/calculateTaxes', { products: items }, { root: true })

    if (suggestions) {
      dispatch('search-suggestions/setSearchSuggestions', suggestions, { root: true })
    }

    await dispatch('loadAvailableFiltersFrom', {
      aggregations,
      category: searchCategory,
      filters: searchQuery.filters
    })

    commit(types.CATEGORY_SET_SEARCH_PRODUCTS_STATS, { perPage, start, total })
    commit(types.CATEGORY_SET_PRODUCTS, items)

    return items
  },

  async loadColourAttributes ({ commit, getters }) {
    let searchQuery = new SearchQuery()
    searchQuery.applyFilter({ key: 'attribute_code', value: { 'eq': 'colour' } })
    const elasticSearchQuery = await elasticsearch.buildQueryBodyFromSearchQuery({ config, queryChain: bodybuilder(), searchQuery })

    try {
      const { items: [{ options }] } = await quickSearchByQuery({
        entityType: 'attribute',
        query: elasticSearchQuery
      })
      commit('SET_COLOUR_ATTRIBUTES', options)
    } catch (e) {
      commit('SET_COLOUR_ATTRIBUTES', [])
    }
  },
  async loadQueryCategories ({ commit, getters }, { route, category, pageSize = 50, query = null } = {}) {
    const searchCategory = category || getters.getCategoryFrom(route.path) || {}
    const categoryMappedFilters = getters.getFiltersMap[searchCategory.id]

    const searchQuery = getters.getCurrentFiltersFrom(route[products.routerFiltersSource], categoryMappedFilters)
    let filterQr = buildFilterProductsQuery(searchCategory, searchQuery.filters)

    if (query) {
      filterQr = filterQr.setSearchText(query)
    }

    const cacheTags = []
    const appliedFilters = filterQr.getAppliedFilters()
    const categoryFilter = appliedFilters.find(({ attribute }) => attribute === 'category_ids')
    if (categoryFilter && categoryFilter.value) {
      Object.values(categoryFilter.value).forEach(categoryId => {
        cacheTags.push(`C${categoryId}`)
      })
    }

    const { items } = await quickSearchByQuery({
      query: filterQr,
      includeFields: ['category'],
      excludeFields: entities.productList.excludeFields,
      size: 1000,
      cacheTags
    })

    const categories = flatten(items.map(product => product.category))

    const unique = uniq(categories.map(cat => cat.category_id).filter(catId => catId !== category.id))
    const searchQueryCats = unique.map(id => categories.find(category => category.category_id === id))
    if (Vue.prototype.$cacheTags) {
      searchQueryCats.forEach(category => {
        const categoryId = category.category_id
        if (categoryId) {
          Vue.prototype.$cacheTags.add(`C${categoryId}`)
        }
      })
    }

    commit('CATEGORY_SET_SEARCH_QUERY_CATS', searchQueryCats)

    return items
  },
  async changeRouterFilterParameters (context, query) {
    const route: any = { [products.routerFiltersSource]: query }

    if (router.currentRoute.query.page) {
      if (!route.query) route.query = {}
      route.query.page = 1
      route.query = omit(route.query, ['page'])
    }

    await router.push(route)
  },
  async loadCategoryMinMaxPrice ({ commit }, { category, query = null }) {
    let maxQuery = new SearchQuery()
    maxQuery = maxQuery.applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
    maxQuery = maxQuery.applyFilter({ key: 'status', value: { 'in': [0, 1] } })

    let categoryIds = [category.id]
    if (category.id === 2 && category.children_data) {
      if (query) {
        maxQuery.setSearchText(query)
      }
    }

    // move this here for cases when main category have no products but cat.children_data has
    let recurCatFinderBuilder = (category) => {
      if (!category) {
        return
      }

      if (!category.children_data) {
        return
      }

      for (let sc of category.children_data) {
        if (sc && sc.id) {
          categoryIds.push(sc.id)
        }
        recurCatFinderBuilder(sc)
      }
    }
    recurCatFinderBuilder(category)

    maxQuery = maxQuery.applyFilter({ key: 'category_ids', value: { 'in': categoryIds } })
    const cacheTags = categoryIds.map(categoryId => `C${categoryId}`)

    const maxResponse = await quickSearchByQuery({
      query: maxQuery,
      sort: 'final_price:desc',
      size: 1,
      includeFields: ['final_price'],
      cacheTags
    })

    const minResponse = await quickSearchByQuery({
      query: maxQuery,
      sort: 'final_price:asc',
      size: 1,
      includeFields: ['final_price'],
      cacheTags
    })

    if (maxResponse && maxResponse.items && maxResponse.items.length) {
      const first = maxResponse.items[0]
      commit('SET_MAX_PRICE', Math.ceil(parseFloat(first.final_price)))
    }

    if (minResponse && minResponse.items && minResponse.items.length) {
      const first = minResponse.items[0]
      commit('SET_MIN_PRICE', Math.floor(parseFloat(first.final_price)))
    }
  }
}

export default actions
