import config from 'config'
import i18n from '@vue-storefront/i18n'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';

const validateProduct = (product: CartItem): string[] => {
  const errors = []

  if (config.products?.validation?.allowZeroPrice ? product.price_incl_tax < 0 : product.price_incl_tax <= 0) {
    errors.push(i18n.t('Incorrect product price!'))
  }

  if (product.qty == null && config.products?.validation?.requireQty) {
    errors.push(i18n.t('Missing product quantity data!'))
  }

  if (product.type_id == null && config.products?.validation?.requireTypeId) {
    errors.push(i18n.t('Missing product type data!'))
  }

  if (product.product_option == null && config.products?.validation?.requireProductOption) {
    errors.push(i18n.t('Missing product option data!'))
  }

  if (product.errors !== null && typeof product.errors !== 'undefined') {
    for (const errKey in product.errors) {
      if (product.errors[errKey]) {
        errors.push(product.errors[errKey])
      }
    }
  }

  return errors
};

export default validateProduct;
