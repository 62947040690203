import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'

export const KEY = 'magentoConfig'

export const MagentoConfig: StorefrontModule = ({ store }) => {
  store.registerModule(KEY, module)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('config', {
      queryProcessor: (query) => {
        // function that can modify the query each time before it's being executed
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'config', start, size)
      }
    })
  })
}
