import { Module } from 'vuex'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'
import RootState from '@vue-storefront/core/types/RootState'
import merge from 'lodash-es/merge'
import actions from './actions'
import { productModule } from '@vue-storefront/core/modules/catalog/store/product'

const productExtendedModule = merge(productModule, {
  actions
})

export const productNewModule: Module<ProductState, RootState> = productExtendedModule
