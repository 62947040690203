import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>, tagManagerId) {
  if (isEnabled(tagManagerId)) {
    const GTM: VueGtm = (Vue as any).gtm

    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    // Fixes issue of sending multiple items when browsing page, e.g. opening product, without this, it will send
    // All items from category page as well
    const clearDataLayerItems = () => {
      window?.dataLayer.push({ items: null });
    }

    store.subscribe(({ type, payload }, state) => {
      // TODO - categories not implemented really correctly. We are simply taking first category.
      // the .category returns all subcategories where product is assigned, but we need the whole hierarchy.
      // Since I didn't saw anything related to categories in GA, I'm leaving it like it is.
      if (type === 'cart/cart/ADD') {
        clearDataLayerItems();
        GTM.trackEvent({
          event: 'add_to_cart',
          currency: currencyCode,
          value: parseFloat(payload.product.price_incl_tax.toFixed(2)),
          items: [
            {
              item_id: payload.product.sku,
              item_name: payload.product.name,
              price: payload.product.price_incl_tax.toFixed(2),
              quantity: payload.product.qty,
              item_category: payload.product.category ? payload.product.category[0].name : ''
            }
          ]
        });
      }

      if (type === 'cart/cart/DEL') {
        clearDataLayerItems();
        GTM.trackEvent({
          event: 'remove_from_cart',
          currency: currencyCode,
          value: parseFloat(payload.product.price_incl_tax.toFixed(2)),
          items: [
            {
              item_id: payload.product.sku,
              item_name: payload.product.name,
              price: payload.product.price_incl_tax.toFixed(2),
              quantity: payload.product.qty,
              item_category: payload.product.category ? payload.product.category[0].name : ''
            }
          ]
        });
      }

      if (type === 'recently-viewed/recently-viewed/ADD') {
        clearDataLayerItems();
        GTM.trackEvent({
          event: 'view_item',
          currency: currencyCode,
          value: parseFloat(payload.product.price_incl_tax.toFixed(2)),
          items: [
            {
              item_id: payload.product.sku,
              item_name: payload.product.name,
              price: payload.product.price_incl_tax.toFixed(2),
              item_category: payload.product.category ? payload.product.category[0].name : ''
            }
          ]
        });
      }

      // TODO - couldn't find a way to add item_list_name which would be the categories name.
      // There is another type (category-next/category/ADD_CATEGORY), but in that, we could only get the category
      // data without products.
      if (type === 'category-next/category/SET_PRODUCTS' || type === 'category-next/category/ADD_PRODUCTS') {
        clearDataLayerItems();
        GTM.trackEvent({
          event: 'view_item_list',
          items: payload.map(item => ({
            item_id: item.sku,
            item_name: item.name,
            price: item.price_incl_tax.toFixed(2)
          }))
        });
      }
      if (type === 'order/orders/LAST_ORDER_CONFIRMATION') {
        const orderId = payload.confirmation.orderNumber
        store.dispatch(
          'user/getOrdersHistory',
          { refresh: true, useCache: false }
        ).then(() => {
          clearDataLayerItems();

          const order = state.user.orders_history ? state.user.orders_history.items.find((order) => order['entity_id'].toString() === orderId) : null;
          const orderValue = order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.grand_total ? state.cart.platformTotals.grand_total : 0;

          GTM.trackEvent({
            event: 'fireRemarketingTag',
            google_ads: {
              event: 'purchase',
              value: orderValue,
              items: payload.order.products.map(item => ({
                item_id: item.sku,
                item_name: item.name,
                price: item.price_incl_tax.toFixed(2),
                quantity: item.qty
              })),
              transaction_id: payload.confirmation.orderNumber,
              currency: currencyCode
            }
          })
        })
      }
    })
  }
}
