import rootStore from '@vue-storefront/core/store'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import Vue from 'vue'
import { TaskQueue } from '@vue-storefront/core/lib/sync'

export const CustomerReviewStatus = {
  namespaced: true,
  state: {
    isReviewSubmit: true,
    allReviewsSubmit: {}
  },
  mutations: {
    UPDATE_REVIEW_SUBMIT_STATUS (state, data) {
      state.isReviewSubmit = data || false
    },
    UPDATE_ALL_REVIEWS_SUBMIT_STATUS (state, data) {
      Vue.set(state.allReviewsSubmit, data.productId, data.result || false)
    }
  },
  actions: {
    submitReview ({ dispatch, rootState, commit }, { productId }) {
      commit('customerReviewStatus/UPDATE_REVIEW_SUBMIT_STATUS', true, { root: true })
      commit('customerReviewStatus/UPDATE_ALL_REVIEWS_SUBMIT_STATUS', { productId: productId, result: true }, { root: true })
    },
    async isReviewSubmit ({ dispatch, rootState, commit }, { productId }) {
      const customerToken = rootStore.getters['user/getToken']
      const customerID = rootStore.state.user.current.id.toString();
      const url = processLocalizedURLAddress(config.customerProductReview.endpoint.get)
        .replace('{{customerId}}', customerID)
        .replace('{{productId}}', productId)
        .replace('{{customerToken}}', customerToken)

      const response = await TaskQueue.execute({
        url: url,
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      if (response.code === 200) {
        commit('customerReviewStatus/UPDATE_REVIEW_SUBMIT_STATUS', response.result, { root: true })
        commit('customerReviewStatus/UPDATE_ALL_REVIEWS_SUBMIT_STATUS', { productId: productId, result: response.result }, { root: true })
      }
    }
  },
  getters: {
    getIsReviewSubmit (state) {
      return state.isReviewSubmit
    },
    getAllReviewsSubmit (state) {
      return state.allReviewsSubmit
    }
  }
}
