import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import fetch from 'isomorphic-fetch'
import { processLocalizedURLAddress, processURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { EagleState } from '../types/eagleState'

const actions: ActionTree<EagleState, RootState> = {
  async createUserFromOrder ({ dispatch, commit }, { orderId, email, password, token, captchaToken }) {
    return new Promise((resolve, reject) => {
      fetch(processURLAddress(config.checkout.registerUser), {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
          orderId: orderId,
          email: email,
          password: password,
          token: token,
          captchaToken: captchaToken
        })
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async setCurrentCurrencyCode ({ dispatch, commit }, { currency }) {
    return new Promise((resolve, reject) => {
      fetch(processURLAddress(config.store.endpoint), {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
          currency: currency
        })
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async getAvailableCurrencyCodes ({ getters, commit }) {
    const response = await TaskQueue.execute({
      url: processLocalizedURLAddress(config.store.endpoint_check),
      payload: {
        method: 'GET',
        mode: 'cors'
      }
    })

    if (response.code === 200 && response.result) {
      return response.result
    }
  },
  async getCurrentCurrency ({ getters, commit }) {
    const response = await TaskQueue.execute({
      url: processLocalizedURLAddress('/api/ext/eagle-leather/get-current-currency'),
      payload: {
        method: 'GET',
        mode: 'cors'
      }
    })

    if (response.code === 200 && response.result) {
      return response.result
    }
  }
}

export default actions
