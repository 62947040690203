import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import OrderState from '@vue-storefront/core/modules/order/types/OrderState'
import merge from 'lodash-es/merge'
import actions from './actions'
import { orderStore } from '@vue-storefront/core/modules/order/store'

const orderExtendedModule = merge(orderStore, {
  actions
})

export const orderNewModule: Module<OrderState, RootState> = orderExtendedModule
