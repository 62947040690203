import { Logger } from '@vue-storefront/core/lib/logger'
import { Module } from 'vuex'
import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { processURLAddress } from '@vue-storefront/core/helpers'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'

export const countryDataStore: Module<any, any> = {
  namespaced: true,
  actions: {
    async getCountryData (context, countryId: string) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.country_data.endpoint.replace('{{country}}', encodeURIComponent(countryId))),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })
      if (response.code === 200) {
      } else {
        throw new Error(response.result.errorMessage)
      }
      return response
    }
  }
}
