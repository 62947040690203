import { StorefrontModule } from 'core/lib/modules'
import { storeCreditsStore } from './store'
import { extendStore } from '@vue-storefront/core/helpers'
import { storeCreditsCheckoutModule } from './store/store-credits-checkout'

export const KEY = 'store-credits'
export const CART_KEY = 'cart'
export const StoreCreditsModule: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, storeCreditsStore)
  extendStore(CART_KEY, storeCreditsCheckoutModule)
}
