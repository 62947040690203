import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from 'core/modules/user/types/UserState'

interface ExtendedUserState extends UserState {
  reviewMode: boolean
}

const getters: GetterTree<ExtendedUserState, RootState> = {
  getReviewMode: (state) => state.reviewMode
}

export default getters
