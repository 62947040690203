import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import CheckoutState from '@vue-storefront/core/modules/checkout/types/CheckoutState'

const actions: ActionTree<CheckoutState, RootState> = {
  async updatePdPropValue ({ commit }, payload) {
    commit(types.CHECKOUT_UPDATE_PD_PROP_VALUE, payload)
  }
}

export default actions
