import RootState from '@vue-storefront/core/types/RootState'
import ConfigState from '../types/ConfigState'
import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const module: Module<ConfigState, RootState> = {
  namespaced: true,
  state: {
    list: {}
  },
  actions,
  mutations,
  getters
}
