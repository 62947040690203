import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'

export const newArrivalsStore = {
  namespaced: true,
  state: {
    newArrivals: [],
    currentFeatureCategoryId: null
  },
  actions: {
    async loadNewArrivals ({ commit, dispatch }, id) {
      const today = new Date().toISOString().slice(0, 10);

      let filters = [
        {
          key: 'category_ids',
          value: { 'eq': id }
        },
        {
          key: 'is_featured_product',
          value: { 'eq': true }
        },
        {
          key: 'stock.is_in_stock',
          value: { 'eq': true }
        }
      ]

      // filter for set product as new form in admin
      let filtersNews = [
        {
          key: 'news_from_date',
          value: { 'lte': today }
        },
        {
          key: 'news_to_date',
          value: { 'gte': today }
        }
      ]

      let queryNew = prepareQuery({ filters: [...filters, ...filtersNews] })
      const responseNew = await dispatch('product/findProducts', {
        query: queryNew,
        size: 8,
        sort: 'news_from_date:desc',
        options: {
          populateRequestCacheTags: false,
          prefetchGroupProducts: false,
          assignProductConfiguration: false
        }
      }, { root: true })

      let items = []
      responseNew?.items.forEach(responseItem => items.push(responseItem))

      // if less than 8 then get rest from out-side set new date range
      if (items?.length < 8) {
        const query = prepareQuery({ filters: filters })

        const response = await dispatch('product/findProducts', {
          query: query,
          size: (8 - responseNew.items.length),
          options: {
            populateRequestCacheTags: false,
            prefetchGroupProducts: false,
            assignProductConfiguration: false
          }
        }, { root: true })

        response?.items.forEach(responseItem => items.push(responseItem))
      }

      const uniqueItems = items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
      commit('SET_CURRENT_FEATURE_CATEGORY_ID', id)
      commit('SET_NEWARRIVALS', uniqueItems)
    }
  },
  mutations: {
    SET_NEWARRIVALS (state, products) {
      state.newArrivals = products || []
    },
    SET_CURRENT_FEATURE_CATEGORY_ID (state, id) {
      state.currentFeatureCategoryId = id
    }
  },
  getters: {
    getNewArrivals (state) {
      return state.newArrivals
    },
    getCurrentFeatureCategoryId (state) {
      return state.currentFeatureCategoryId
    }
  }
}
