import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import OrderState from '@vue-storefront/core/modules/order/types/OrderState'
import * as types from '@vue-storefront/core/modules/order/store/mutation-types'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const actions: ActionTree<OrderState, RootState> = {
  handlePlacingOrderFailed ({ commit, dispatch }, { newOrder, currentOrderHash }) {
    const order = { newOrder, transmited: false }
    commit(types.ORDER_REMOVE_SESSION_ORDER_HASH, currentOrderHash)
    dispatch('enqueueOrder', { newOrder: order })

    EventBus.$emit('notification-progress-stop')
  }
}

export default actions
