import { MutationTree } from 'vuex'
import { SearchSuggestionsState } from '../types/searchSuggestionsState'
import { SET_SEARCH_SUGGESTIONS } from './mutation-types'

const mutations: MutationTree<SearchSuggestionsState> = {
  [SET_SEARCH_SUGGESTIONS] (state, suggestions) {
    state.suggestions = suggestions
  }
}

export default mutations
