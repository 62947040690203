import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { productFilterChangeByManyOptions } from './events'
import { isServer } from '@vue-storefront/core/helpers'

export const ConfigurableProductsModule: StorefrontModule = async function ({ store, router, appConfig }) {
  if (!isServer) {
    EventBus.$on('filter-changed-product-all-filters', filterOptions => productFilterChangeByManyOptions(filterOptions, store, router))
  }
}
