import { Module } from 'vuex'
import RootState from 'core/types/RootState'
import AfterPayState from './state'
import { actions } from './actions'
import * as types from './mutation-types'

export const module: Module<AfterPayState, RootState> = {
  namespaced: true,
  actions,
  state: {
    methods: null,
    token: null,
    message: null
  },
  mutations: {
    [types.SET_BACKEND_PAYMENT_AFTERPAY] (state, paymentMethods) {
      state.methods = paymentMethods
    },
    [types.SET_AFTERPAY_TOKEN] (state, token) {
      state.token = token
    },
    [types.SET_AFTERPAY_MESSAGE] (state, message) {
      state.message = message
    }
  }
}
